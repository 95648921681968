import styles from "./fbPixelItem.module.scss";
import trashIcon from "src/assets/trash.svg";
import editIcon from "src/assets/edit.svg";
import ActionModal from "src/_elements/actionModal/actionModal";
import { useState } from "react";
import { deleteFacebookPixelIdApi } from "src/_utils/api/api";
import FbModal from "../fbModal/fbModal";
import { truncateText } from "src/_utils/truncateText";

const FbPixelItem: React.FC<any> = ({ item, setIsLoading }: any) => {
  const [isShowDeleteFbModal, setIsShowDeleteFbModal] = useState(false);
  const [isShowFbModal, setIsShowFbModal] = useState(false);

  const handleCloseFbModal = () => {
    setIsShowFbModal(false);
  };

  const handleCloseDeleteFbModal = () => {
    setIsShowDeleteFbModal(false);
  };

  const handleDeleteFb = () => {
    deleteFacebookPixelIdApi(item.id).then(() => {
      setIsLoading(true);
      handleCloseDeleteFbModal();
    });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.buttonsWrap}>
          <button>
            <img
              onClick={() => setIsShowFbModal(true)}
              src={editIcon}
              alt="edit"
            />
          </button>
          <button onClick={() => setIsShowDeleteFbModal(true)}>
            <img src={trashIcon} alt="delete" />
          </button>
        </div>
        <p className={styles.label}>Name:</p>
        <p className={styles.value}>{truncateText(item.displayName, 15)}</p>
        <p className={styles.label}>Facebook pixel id:</p>
        <p className={styles.value}>{truncateText(item.facebookPixelId, 15)}</p>
      </div>
      <FbModal
        isShow={isShowFbModal}
        handleClose={handleCloseFbModal}
        item={item}
        setIsLoading={setIsLoading}
        isEdit
      />
      <ActionModal
        title={`Delete "${item.displayName}?" facebook pixel`}
        subtitle="This will permanently delete selected facebook pixel."
        isShowModal={isShowDeleteFbModal}
        handleCloseModal={handleCloseDeleteFbModal}
        handleAction={handleDeleteFb}
        actionButtonText="Delete"
      />
    </>
  );
};

export default FbPixelItem;
