import { useDispatch } from "react-redux";
import styles from "./actionContentFooter.module.scss";
import {
  setIsShowPricingModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";

const ActionContentFooter: React.FC<any> = ({ title, subtitle }) => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  return (
    <div className={styles.wrapper}>
      <h3>{title}</h3>
      <div className={styles.divider} />
      <p
        dangerouslySetInnerHTML={{ __html: subtitle }}
        className={styles.subtitle}
      />
      <button
        onClick={
          token
            ? () => dispatch(setIsShowPricingModalAC(true))
            : () => dispatch(setIsShowRegisterModalAC(true))
        }
      >
        Try it for yourself
      </button>
    </div>
  );
};

export default ActionContentFooter;
