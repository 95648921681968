import PaymentPlan from "src/pages/homePage/payments/paymentPlan/paymentPlan";
import styles from "./nextPlan.module.scss";
import arrow from "./../../../assets/arrow.svg";

const NextPlan: React.FC<any> = ({
  nextPlanData,
  isCurrentPlanChanged,
  nextPlanPrice,
  pricePerForm,
  isYearly,
  nextPlanStartDate,
  nextPlanEndDate,
  boughtPlanFormsCount
}: any) => {
  return (
    <>
      {nextPlanData && isCurrentPlanChanged && (
        <img className={styles.arrow} height="50px" src={arrow} alt="arrow" />
      )}
      <div className={styles.planWrapper}>
        {nextPlanData && isCurrentPlanChanged && (
          <>
            <h3>Your next plan:</h3>
            <PaymentPlan
              price={nextPlanPrice}
              pricePerForm={pricePerForm}
              title={nextPlanData?.name}
              maxDomains={nextPlanData?.maxDomains}
              maxSubmissionsPerMonth={nextPlanData?.maxSubmissionsPerMonth}
              isYearly={isYearly}
              boughtPlanFormsCount={boughtPlanFormsCount}
              isNextPlan
            />
          </>
        )}
        {nextPlanData && isCurrentPlanChanged && (
          <p className={styles.period}>
            Your next plan starts: <span>{nextPlanStartDate}</span> to{" "}
            <span>{nextPlanEndDate}</span>
          </p>
        )}
      </div>
    </>
  );
};

export default NextPlan;
