import ContentFooter from "src/pages/whoWeServe/tradePage/contentSection/contentFooter/contentFooter";
import { IContentSection } from "../../interfaces/interfaces";
import styles from "./contentSection.module.scss";

const ContentSection: React.FC<IContentSection> = ({
  isReversed,
  icon,
  title,
  description,
  img,
  title1,
  title2,
  subtitle1,
  subtitle2,
}: IContentSection) => {
  return (
    <div
      className={`${styles.wrapper} ${
        isReversed ? styles.reversedWrapper : ""
      }`}
    >
      <div className={styles.textContent}>
        <div className={styles.iconWrap}>
          <img src={icon} alt={title} />
        </div>
        <p className={styles.title}>{title}</p>
        <div className={styles.divider} />
        <p
          dangerouslySetInnerHTML={{ __html: description }}
          className={styles.description}
        />
        {title1 && title2 && subtitle1 && subtitle2 && (
          <ContentFooter
            title1="Easy-to-Use Interface"
            title2="Tailored for everything"
            subtitle1="No coding needed—just drag, drop, and customize."
            subtitle2="Designed to meet the unique needs."
          />
        )}
      </div>
      <div className={styles.imgWrap}>
        <img src={img} alt={title} />
      </div>
    </div>
  );
};

export default ContentSection;
