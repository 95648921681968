
import React, { useEffect, useState } from "react";
import { getCurrentUserInfoApi } from "src/_utils/api/api";
import styles from "./zapierEmbedElement.module.scss";

const ZapierEmbedElement: React.FC = () => {
  const token = localStorage.getItem("token");
  const [currentUser, setCurrentUser] = useState<{ email: string; name: string } | null>(null);
  const isProd = process.env.REACT_APP_ENVIRONMENT === "production";

  useEffect(() => {
    if (token) {
      getCurrentUserInfoApi().then((res) => {
        setCurrentUser(res.data.data);
      });
    }
  }, [token]);
  
  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const [firstName, lastName] = currentUser.name.split(" ");

    const script = document.createElement("script");
    script.type = "module";
    script.src = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
    document.head.appendChild(script);

    const stylesheet = document.createElement("link");
    stylesheet.rel = "stylesheet";
    stylesheet.href = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";
    document.head.appendChild(stylesheet);

    const element: any = document.createElement("zapier-workflow");
    element.signUpEmail = currentUser.email;
    element.signUpFirstName = firstName || 'FirstName';
    element.signUpLastName = lastName || 'SecondName';
    element.clientId = "knDaT0aX7UvekWBvlP1U5TkDEhXG24rbIeCwMx8k";
    element.theme = "light";
    element.introCopyDisplay = "show";
    element.manageZapsDisplay = "show";
    element.appSearchBarDisplay = "show";
    element.appLimit = 2;
    element.zapCreateFromScratchDisplay = "show";
    // element.templateIds = "255568384,255568368,255568376,255568391";
    element.templateStyle = "card";

    const container = document.querySelector("#zapier-container");
    if (container) {
      container.appendChild(element);
    }

    return () => {
      if (container) {
        container.innerHTML = "";
      }
    };
  }, [currentUser]);
  return <div className={styles.outerWrapper}>
    <div className={styles.wrapper}>
      <div className={`${styles.zapContainer} ${!isProd ? styles.blurred : ""}`} id="zapier-container" ></div>
      {!isProd && <div className={styles.devWrapper}>
        <div className={styles.devZapText}>
        <div className={styles.devZapTextWrap}><b>Zapier integration is only available on the production site. Please visit the production site at <a href="https://leadquiz.com">leadquiz.com</a> to use LeadQuiz Zapier integration.</b></div>
        </div>
        </div>}
    </div>
  </div>;
};

export default ZapierEmbedElement;
