import React, { useEffect, useState } from "react";
import styles from "./thankYouPage.module.scss";
import { getPublishedFormApi } from "src/_utils/api/api";
import ROUTES from "src/_utils/routes/routes";
import { useLocation } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

const ThankYouPage = () => {
  const subdomain = window.location.hostname.split(".")[0];

  const location = useLocation();

  const [endingPageText, setEndingPageText] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getPublishedFormApi(subdomain)
      .then((res) => {
        setEndingPageText(res.data.data.formsData?.endingPageText);
        setCompanyLogo(res.data.data.formsData?.logo);
        setIsLoading(false);

        if (location.pathname === ROUTES.THANK_YOU) {
          const scriptId = `facebook-pixel-${res.data.data?.facebookPixelData?.facebookPixelId}`;

          if (
            res.data.data?.facebookPixelData?.facebookPixelId &&
            !document.getElementById(scriptId)
          ) {
            const script = document.createElement("script");
            script.id = scriptId;
            script.innerHTML = `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${res.data.data?.facebookPixelData?.facebookPixelId}');
                fbq('track', 'PageView');
                fbq('track', 'Lead');
              `;
            document.head.appendChild(script);

            const noscript = document.createElement("noscript");
            noscript.id = scriptId + "-noscript";
            noscript.innerHTML = `<img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=${res.data.data?.facebookPixelData?.facebookPixelId}&ev=PageView&noscript=1" />`;
            document.body.appendChild(noscript);
          }

          const googleScriptId = `google-ads-${res.data.data.googleAdsData?.conversionId}`;

          if (
            res.data.data.googleAdsData?.conversionId &&
            !document.getElementById(googleScriptId)
          ) {
            const script = document.createElement("script");
            script.id = googleScriptId;
            script.innerHTML = `
                (function(w, d, s, l, i) {
                  w[l] = w[l] || [];
                  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                  var f = d.getElementsByTagName(s)[0],
                      j = d.createElement(s),
                      dl = l != 'dataLayer' ? '&l=' + l : '';
                  j.async = true;
                  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                  f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', '${res.data.data.googleAdsData?.conversionId}');
              `;
            document.head.appendChild(script);

            const noscript = document.createElement("noscript");
            noscript.id = googleScriptId + "-noscript";
            noscript.innerHTML = `<img height="1" width="1" style="display:none"
                src="https://www.googleadservices.com/pagead/conversion/${res.data.data.googleAdsData?.conversionId}/?label=${res.data.data.googleAdsData?.conversionLabel}&guid=ON&script=0" />`;
            document.body.appendChild(noscript);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [location.pathname]);

  return (
    <div className={styles.wrapper}>
      {!isLoading ? (
        <>
          <img
            className={styles.companyLogo}
            src={companyLogo}
            alt="company logo"
          />
          <h1>{endingPageText}</h1>
        </>
      ) : (
        <RotatingLines
          visible={true}
          width="30"
          strokeWidth="5"
          animationDuration="0.75"
          strokeColor="black"
        />
      )}
    </div>
  );
};

export default ThankYouPage;
