import { apiRoutes } from "src/_utils/api/api";
import styles from "./callBooking.module.scss";
import callIcon from "src/assets/call.svg";
import { useDispatch } from "react-redux";
import { setIsShowRegisterModalAC } from "src/store/actions";
import { loadStripe } from "@stripe/stripe-js";

const CallBooking: React.FC<any> = ({
  isUserChangingPlan,
  handleOnlyChangePlan,
  pricePerForm,
  priceId,
  id,
  formsCount,
}: any) => {
  const dispatch = useDispatch();

  const handleGetPlan = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      const stripePromise = loadStripe(
        process.env.REACT_APP_PUBLISHABLE_KEY as string
      );

      const stripe = await stripePromise;

      let payload;

      if (pricePerForm) {
        payload = {
          planId: id,
          priceId,
          formsCount,
        };
      } else {
        payload = {
          planId: id,
          priceId,
        };
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${apiRoutes.stripeCreateSession}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      localStorage.setItem("purchaseSuccess", "true");

      const data = await response.json();

      const sessionId = data.data.sessionId;

      //@ts-ignore
      const { error } = await stripe?.redirectToCheckout({
        sessionId,
      });
    } else {
      dispatch(setIsShowRegisterModalAC(true));
    }
  };

  const handleChangeToSelectedPlanAndBookCall = async () => {
    handleOnlyChangePlan();

    const token = localStorage.getItem("token");

    if (token) {
      const stripePromise = loadStripe(
        process.env.REACT_APP_PUBLISHABLE_KEY as string
      );

      const stripe = await stripePromise;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${apiRoutes.createBuyCallSession}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      localStorage.setItem("purchaseSuccess", "true");

      const data = await response.json();

      const sessionId = data.data.sessionId;

      //@ts-ignore
      const { error } = await stripe?.redirectToCheckout({
        sessionId,
      });
    } else {
      dispatch(setIsShowRegisterModalAC(true));
    }
  };

  const handleGetSelectedPlanAndBookCall = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      const stripePromise = loadStripe(
        process.env.REACT_APP_PUBLISHABLE_KEY as string
      );

      const stripe = await stripePromise;

      let payload;

      if (pricePerForm) {
        payload = {
          planId: id,
          priceId,
          formsCount,
          purchaseOnboarding: true,
        };
      } else {
        payload = {
          planId: id,
          priceId,
          purchaseOnboarding: true,
        };
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${apiRoutes.stripeCreateSession}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      localStorage.setItem("purchaseSuccess", "true");

      const data = await response.json();

      const sessionId = data.data.sessionId;

      //@ts-ignore
      const { error } = await stripe?.redirectToCheckout({
        sessionId,
      });
    } else {
      dispatch(setIsShowRegisterModalAC(true));
    }
  };

  return (
    <div className={styles.wrapper}>
      <img src={callIcon} alt="call" />
      <p className={styles.title}>
        Get the Most Out of LeadQuiz with Expert Onboarding!
      </p>
      <p className={styles.headerLabel}>One Time Fee of $200</p>
      <p className={styles.subtitle}>
        Unlock the full potential of your LeadQuiz subscription by booking a 1:1
        onboarding call.
        <br />
        <br />
        Our experts will help you:
      </p>
      <ul className={styles.list}>
        <li>Set up your account for success</li>
        <li>Create customized forms that convert</li>
        <li>Seamlessly integrate with your CRM</li>
      </ul>
      {isUserChangingPlan ? (
        <div className={styles.buttonsWrap}>
          <button onClick={handleChangeToSelectedPlanAndBookCall}>
            Yes, Upgrade My Plan with Expert Onboarding
          </button>
          <button onClick={handleOnlyChangePlan}>
            Continue Without Onboarding
          </button>
        </div>
      ) : (
        <div className={styles.buttonsWrap}>
          <button onClick={handleGetSelectedPlanAndBookCall}>
            Yes, Upgrade My Plan with Expert Onboarding
          </button>
          <button onClick={handleGetPlan}>Continue Without Onboarding</button>
        </div>
      )}
    </div>
  );
};

export default CallBooking;
