import styles from "./contentFooter.module.scss";

const ContentFooter: React.FC<any> = ({
  title1,
  title2,
  subtitle1,
  subtitle2,
}) => {
  return (
    <div className={styles.contentFooterWrap}>
      <div className={styles.contentFooterItem}>
        <p className={styles.title}>{title1}</p>
        <p className={styles.subtitle}>{subtitle1}</p>
      </div>
      <div className={styles.contentFooterItem}>
        <p className={styles.title}>{title2}</p>
        <p className={styles.subtitle}>{subtitle2}</p>
      </div>
    </div>
  );
};

export default ContentFooter;
