import Header from "src/pages/homePage/header/header";
import styles from "./../article.module.scss";
import Footer from "src/pages/homePage/footer/footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const HowToIntegrateBuilderPrime = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Support - How to intergade builder prime</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link
          rel="canonical"
          href="https://leadquiz.com/support/how-to-integrate-builder-prime"
        />
        <meta
          property="og:title"
          content="LeadQuiz | Support - How to intergade builder prime"
        />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://leadquiz.com/support/how-to-integrate-builder-prime"
        />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <h1>How to Integrate LeadQuiz with Builder Prime through Zapier</h1>
        <p>
          Integrating LeadQuiz with Builder Prime using Zapier automates your
          lead management process, enabling form submissions to flow directly
          into Builder Prime for tracking, follow-up, and management. Follow
          these steps to connect your LeadQuiz forms with Builder Prime.
        </p>

        <b className={styles.step}>Step 1: Set Up Your LeadQuiz Form</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Create or open an existing form</b> in your LeadQuiz account.
          </li>
          <ul className={styles.subUl}>
            <li>
              Ensure your form is fully set up with the necessary fields such as
              name, email, phone number, and any specific project details.
            </li>
            <li>This form will act as the trigger in Zapier.</li>
          </ul>
        </ul>

        <b className={styles.step}>Step 2: Log in to Zapier</b>
        <ul className={styles.numericUl}>
          <li>
            Visit{" "}
            <a href="https:/zapier.com/" target="_blank">
              Zapier.com
            </a>{" "}
            and log in to your account. If you don’t have an account, sign up
            for free.
          </li>
          <li>
            From the Zapier dashboard, click "<b>Create Zap</b>" to begin
            setting up your integration.
          </li>
        </ul>

        <b className={styles.step}>Step 3: Set Up the Trigger (LeadQuiz)</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Choose LeadQuiz as the Trigger App:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              In the <b>Choose App & Event</b> search bar, type "LeadQuiz."
            </li>
            <li>
              Select <b>LeadQuiz</b> as the trigger app.
            </li>
          </ul>
          <li>
            <b>Select the Trigger Event:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Choose <b>New Form Submission</b> as the trigger event. This will
              start the zap each time someone submits your LeadQuiz form.
            </li>
          </ul>
          <li>
            <b>Connect Your LeadQuiz Account:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              If this is your first time connecting LeadQuiz to Zapier, you’ll
              need to log in to your LeadQuiz account and authorize the
              connection.
            </li>
          </ul>
          <li>
            <b>Select Your Form:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Choose the specific LeadQuiz form you want to use for this
              integration.
            </li>
          </ul>
          <li>
            <b>Test the Trigger:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Zapier will pull in recent form submissions to use as test data,
              allowing you to verify that the connection works correctly.
            </li>
          </ul>
        </ul>

        <b className={styles.step}>Step 4: Set Up the Action (Builder Prime)</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Choose Builder Prime as the Action App:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              In the <b>Choose App & Event</b> search bar, type "Builder Prime."
            </li>
            <li>
              Select <b>Builder Prime</b> as your action app.
            </li>
          </ul>
          <li>
            <b>Select the Action Event:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Choose the action that you want to happen in Builder Prime when a
              new LeadQuiz submission is received.
            </li>
            <ul className={styles.subUl}>
              <li>
                Common actions include <b>Create New Lead</b> or{" "}
                <b>Create New Project</b>.
              </li>
            </ul>
          </ul>
          <li>
            <b>Connect Your Builder Prime Account:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              If this is your first time using Builder Prime with Zapier, you’ll
              need to log in to your Builder Prime account and follow the
              prompts to authorize Zapier.
            </li>
          </ul>
          <li>
            <b>Map the Fields:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Map the fields from LeadQuiz to the appropriate fields in Builder
              Prime.
            </li>
            <ul className={styles.subUl}>
              <li>Example field mappings:</li>
              <ul className={styles.subUl}>
                <li>
                  <b>Full Name</b> in LeadQuiz → <b>Lead Name</b> in Builder
                  Prime.
                </li>
                <li>
                  <b>Email Address</b> in LeadQuiz → <b>Email</b> in Builder
                  Prime.
                </li>
                <li>
                  <b>Phone Number</b> in LeadQuiz → <b>Phone</b> in Builder
                  Prime.
                </li>
                <li>
                  Any custom fields from your LeadQuiz form can also be mapped
                  to relevant fields in Builder Prime.
                </li>
              </ul>
            </ul>
          </ul>
          <li>
            <b>Test the Action:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Once the fields are mapped, run a test to ensure data from
              LeadQuiz is being correctly sent to Builder Prime. Check Builder
              Prime to see if a new lead or project has been created with the
              test data.
            </li>
          </ul>
        </ul>

        <b className={styles.step}>Step 5: Turn on the Zap</b>
        <ul className={styles.numericUl}>
          <li>
            Once everything is tested and confirmed to be working, click "
            <b>Turn on Zap</b>" to activate the integration.
          </li>
          <li>
            Moving forward, every form submission in LeadQuiz will automatically
            create a new lead or project in Builder Prime.
          </li>
        </ul>

        <b className={styles.step}>
          Step 6: Monitor and Manage the Integration
        </b>
        <ul>
          <li>
            You can monitor zap activity from the Zapier dashboard, where you
            can track submissions, fix errors, or adjust the zap as needed.
          </li>
          <li>
            Modify the zap anytime to refine your workflow or update field
            mappings.
          </li>
        </ul>

        <b className={styles.step}>Tips for Success:</b>
        <ul>
          <li>
            <b>Custom Field Mapping</b>: If your LeadQuiz form contains custom
            fields, ensure that they are correctly mapped to corresponding
            fields in Builder Prime.
          </li>
          <li>
            <b>Test Regularly</b>: Test your zap periodically to ensure that the
            integration is working as expected.
          </li>
          <li>
            <b>Zapier Plan Limits</b>: Make sure you are aware of your Zapier
            usage limits if your forms generate a high volume of submissions.
          </li>
        </ul>
      </div>
      <div className={styles.footerWrap}>
        <Footer />
      </div>
    </div>
  );
};

export default HowToIntegrateBuilderPrime;
