import { Helmet } from "react-helmet";
import styles from "./terms-of-use.module.scss";

function TermsOfUse() {
  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Terms of use</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link rel="canonical" href="https://leadquiz.com/terms-of-use" />
        <meta property="og:title" content="LeadQuiz | Terms of use" />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://leadquiz.com/terms-of-use" />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <div className={styles.wrapper}>
        <p className={styles.privacyPolicy}>LeadQuiz - Terms Of Use</p>
        {/* <p>&nbsp;</p> */}
        <p className={styles.lastUpdated}>
          <strong>Last updated: 9/17/24</strong>
        </p>
        <p>
          Welcome to LeadQuiz! By accessing and using our website and services,
          you agree to comply with and be bound by the following Terms of Use.
          Please read them carefully, and if you do not agree with these terms,
          do not use our services.
        </p>
        <h3>
          <strong>1. Acceptance of Terms</strong>
        </h3>
        <p>
          By creating an account, accessing, or using our services, you
          acknowledge that you have read, understood, and agree to these Terms
          of Use. If you are using LeadQuiz on behalf of a company or other
          legal entity, you represent that you have the authority to bind that
          entity to these terms.
        </p>
        <h3>
          <strong>2. Use of Services</strong>
        </h3>
        <p>
          LeadQuiz provides businesses with tools to create external landing
          pages and lead forms for marketing purposes. By using our services,
          you agree to:
        </p>
        <ul>
          <li>
            Provide accurate, current, and complete information when creating
            your account or submitting details through our platform.
          </li>
          <li>
            Use the service solely for lawful purposes and in compliance with
            all applicable laws and regulations.
          </li>
          <li>
            Refrain from interfering with or disrupting the security, integrity,
            or performance of our services.
          </li>
          <li>
            Not attempt to access any features, data, or systems of LeadQuiz
            that you do not have authorization to use.
          </li>
        </ul>
        <h3>
          <strong>3. User Accounts</strong>
        </h3>
        <p>
          To use LeadQuiz, you must create an account and subscribe to one of
          our paid service plans. You are responsible for:
        </p>
        <ul>
          <li>Keeping your account information confidential and secure.</li>
          <li>
            Ensuring that any information you provide is accurate and up to
            date.
          </li>
          <li>
            All activities that occur under your account, whether or not
            authorized by you.
          </li>
        </ul>
        <p>
          LeadQuiz reserves the right to suspend or terminate accounts if we
          believe you are in violation of these Terms of Use.
        </p>
        <h3>
          <strong>4. Fees and Payment</strong>
        </h3>
        <p>
          LeadQuiz offers only paid services. By subscribing to our services,
          you agree to pay all applicable fees as described during the
          registration process. Fees may include one-time payments, monthly
          subscriptions, or other pricing models based on the service plan you
          choose.
        </p>
        <ul>
          <li>
            All fees are non-refundable unless otherwise stated in our refund
            policy.
          </li>
          <li>
            We may modify our fees from time to time, but we will notify you in
            advance of any changes. Continuing to use the service after a fee
            change constitutes acceptance of the new fees.
          </li>
          <li>
            Failure to pay fees may result in suspension or termination of your
            access to the services.
          </li>
        </ul>
        <h3>
          <strong>5. Intellectual Property</strong>
        </h3>
        <p>
          All content, including text, graphics, logos, icons, and software, is
          the property of LeadQuiz or its licensors and is protected by
          intellectual property laws. You may not reproduce, distribute, modify,
          or create derivative works of our content without prior written
          consent from LeadQuiz.
        </p>
        <h3>
          <strong>6. User-Generated Content</strong>
        </h3>
        <p>
          LeadQuiz allows users to create forms, landing pages, and other custom
          content. You are solely responsible for the content you create and
          submit through our services. By submitting content, you grant LeadQuiz
          a non-exclusive, worldwide, royalty-free license to use, modify,
          display, and distribute your content for the purpose of providing our
          services.
        </p>
        <p>
          You agree that your content will not violate any laws, infringe on any
          third-party rights, or contain any harmful, illegal, or inappropriate
          materials.
        </p>
        <h3>
          <strong>7. Privacy</strong>
        </h3>
        <p>
          Our Privacy Policy governs how we collect and handle your personal
          information. By using our services, you consent to the collection and
          use of your data as outlined in our Privacy Policy.
        </p>
        <h3>
          <strong>8. Third-Party Services</strong>
        </h3>
        <p>
          LeadQuiz may integrate with or link to third-party services, such as
          CRM tools, for your convenience. We do not control or endorse these
          third-party services and are not responsible for their actions,
          privacy practices, or terms. You use third-party services at your own
          risk.
        </p>
        <h3>
          <strong>9. Disclaimer of Warranties</strong>
        </h3>
        <p>
          LeadQuiz provides its services "as is" and without any warranties,
          express or implied. We do not guarantee that:
        </p>
        <ul>
          <li>Our services will meet your specific requirements.</li>
          <li>Our services will be uninterrupted, timely, or error-free.</li>
          <li>Any errors or defects will be corrected.</li>
        </ul>
        <p>
          To the fullest extent permitted by law, LeadQuiz disclaims all
          warranties, including but not limited to implied warranties of
          merchantability, fitness for a particular purpose, or
          non-infringement.
        </p>
        <h3>
          <strong>10. Limitation of Liability</strong>
        </h3>
        <p>
          In no event will LeadQuiz, its directors, employees, or agents be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, including loss of profits, data, or business,
          arising out of or related to your use of the services.
        </p>
        <h3>
          <strong>11. Termination</strong>
        </h3>
        <p>
          LeadQuiz reserves the right to terminate or suspend your access to the
          services at any time, without notice, for conduct that we believe
          violates these Terms of Use or is harmful to other users, us, or third
          parties.
        </p>
        <h3>
          <strong>12. Governing Law</strong>
        </h3>
        <p>
          These Terms of Use are governed by and constructed in accordance with
          the laws of Washington County, Arkansas without regard to its conflict
          of law principles.
        </p>
        <h3>
          <strong>13. Changes to Terms</strong>
        </h3>
        <p>
          We may update these Terms of Use from time to time. Any changes will
          be posted on this page with the updated effective date. Your continued
          use of the services after any such changes constitutes your acceptance
          of the new Terms of Use.
        </p>
        <h3>
          <strong>14. Contact Us</strong>
        </h3>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <p>
          LeadQuiz
          <br />
          Email: <a href="mailto:Support@leadquiz.com">Support@leadquiz.com</a>
        </p>
      </div>
    </div>
  );
}

export default TermsOfUse;
