import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./header.module.scss";
import { useEffect, useRef, useState } from "react";
import WhoWeServeList from "./whoWeServeList/whoWeServeList";
import { getUserRole } from "src/_utils/userRoles/getUserRole";
import ROLES from "src/_utils/userRoles/userRoles";
import ROUTES from "src/_utils/routes/routes";
import companyLogo from "src/assets/homePage/companyLogo.png";
import dropdown from "src/assets/dropdown.svg";
import { getCurrentUserPlanApi } from "src/_utils/api/api";
import {
  setCurrentUserPlanAC,
  setIsCurrentUserPlanLoadingAC,
  setIsShowLoginModalAC,
  setIsShowPricingModalAC,
  setIsShowRegisterModalAC,
  setIsUserPlanActiveAC,
} from "src/store/actions";
import { useDispatch, useSelector } from "react-redux";
import PricingModal from "./pricingModal/pricingModal";
import LoginModal from "./loginModal/loginModal";
import RegisterModal from "./registerModal/registerModal";

const Header = () => {
  const token = localStorage.getItem("token");
  const userRole = getUserRole(token);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isUserPlanActive = useSelector((state: any) => state.isUserPlanActive);

  const [isMobileListOpen, setIsMobileListOpen] = useState(false);
  const [isWhoWeServeListOpen, setIsWhoWeServeListOpen] = useState(false);

  const whoWeServeListRef = useRef<HTMLDivElement>(null); // Создаем реф для списка

  useEffect(() => {
    if (token) {
      dispatch(setIsCurrentUserPlanLoadingAC(true));
      getCurrentUserPlanApi().then((res) => {
        dispatch(setCurrentUserPlanAC(res.data.data));

        const isPlanActive =
          res.data.data?.status === "ACTIVE" &&
          new Date().toISOString().split("T")[0] < res.data.data.endAt;

        dispatch(setIsUserPlanActiveAC(isPlanActive));
        dispatch(setIsCurrentUserPlanLoadingAC(false));
      });
    }
  }, [token]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        whoWeServeListRef.current &&
        !whoWeServeListRef.current.contains(event.target as Node)
      ) {
        setIsWhoWeServeListOpen(false); // Закрываем список при клике вне его области
      }
    };

    if (isWhoWeServeListOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Убираем обработчик при размонтировании
    };
  }, [isWhoWeServeListOpen]);

  const handleLogOut = () => {
    window.location.reload();
    dispatch(setIsUserPlanActiveAC(false));
    localStorage.removeItem("token");
    navigate(ROUTES.HOME);
  };

  const handleLoginMobileScreen = () => {
    setIsMobileListOpen(false);
    dispatch(setIsShowLoginModalAC(true));
  };

  const handleLogoutMobileScreen = () => {
    window.location.reload();
    dispatch(setIsUserPlanActiveAC(false));
    localStorage.removeItem("token");
    setIsMobileListOpen(false);
    navigate(ROUTES.HOME);
  };

  const handleRegisterMobileScreen = () => {
    setIsMobileListOpen(false);
    dispatch(setIsShowRegisterModalAC(true));
  };

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.outerWrapper}>
          <div className={styles.wrapper}>
            <div>
              <Link to={ROUTES.HOME}>
                <img width="170px" height="68px" src={companyLogo} alt="logo" />
              </Link>
            </div>
            <div className={styles.controlPanel}>
              <ul className={styles.navigation}>
                <li>
                  <Link
                    to={ROUTES.HOW_IT_WORKS}
                    className={`${styles.navItem} ${
                      location.pathname === ROUTES.HOW_IT_WORKS
                        ? styles.selected
                        : ""
                    }`}
                  >
                    How it works
                  </Link>
                </li>
                <p
                  className={`${styles.navItem} ${
                    isWhoWeServeListOpen ? styles.selectedPopup : ""
                  }`}
                  onClick={() => setIsWhoWeServeListOpen(!isWhoWeServeListOpen)}
                >
                  <li className={styles.dropDown}>
                    Who we serve
                    <img src={dropdown} />
                  </li>
                </p>
                <li>
                  <Link
                    className={`${styles.navItem} ${
                      location.pathname === ROUTES.FOR_AGENCIES
                        ? styles.selected
                        : ""
                    }`}
                    to={ROUTES.FOR_AGENCIES}
                  >
                    For agencies
                  </Link>
                </li>
                <li>
                  <Link
                    to={ROUTES.PRICING}
                    className={`${styles.navItem} ${
                      location.pathname === ROUTES.PRICING
                        ? styles.selected
                        : ""
                    }`}
                  >
                    Pricing
                  </Link>
                </li>
                {token && (
                  <li>
                    <Link
                      className={`${styles.navItem} ${
                        location.pathname === ROUTES.SETTINGS
                          ? styles.selected
                          : ""
                      }`}
                      to={ROUTES.SETTINGS}
                    >
                      Settings
                    </Link>
                  </li>
                )}
                {token && isUserPlanActive && (
                  <li>
                    <Link
                      className={`${styles.navItem} ${
                        location.pathname === ROUTES.LEADS
                          ? styles.selected
                          : ""
                      }`}
                      to={ROUTES.LEADS}
                    >
                      Leads
                    </Link>
                  </li>
                )}
                {userRole && userRole[0] === ROLES.ADMIN && (
                  <li>
                    <Link
                      className={`${styles.navItem} ${
                        location.pathname === ROUTES.ADMIN_PAGE
                          ? styles.selected
                          : ""
                      }`}
                      to={ROUTES.ADMIN_PAGE}
                    >
                      Admin page
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    className={`${styles.navItem} ${
                      location.pathname === ROUTES.SUPPORT
                        ? styles.selected
                        : ""
                    }`}
                    to={ROUTES.SUPPORT}
                  >
                    Support
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles.buttons}>
              {token ? (
                <button className={styles.login} onClick={handleLogOut}>
                  Logout
                </button>
              ) : (
                <button
                  className={styles.login}
                  onClick={() => dispatch(setIsShowLoginModalAC(true))}
                >
                  Login
                </button>
              )}

              <Link to={token && isUserPlanActive ? ROUTES.FORMS : ""}>
                <button
                  onClick={
                    token && !isUserPlanActive
                      ? () => dispatch(setIsShowPricingModalAC(true))
                      : !token && !isUserPlanActive
                      ? () => dispatch(setIsShowRegisterModalAC(true))
                      : () => {}
                  }
                  className={styles.button}
                >
                  {isUserPlanActive ? "Forms" : "Get started"}
                </button>
              </Link>
            </div>
            {isMobileListOpen ? (
              <div
                onClick={() => setIsMobileListOpen(false)}
                className={styles.closeBtn}
              >
                &times;
              </div>
            ) : (
              <div
                onClick={() => setIsMobileListOpen(true)}
                className={styles.hamburger}
              >
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
          </div>
          {isMobileListOpen && (
            <ul className={styles.mobileListWrapper}>
              <li>
                <Link to={ROUTES.HOW_IT_WORKS}>How it works</Link>
              </li>
              <li>
                <Link to={ROUTES.WHO_WE_SERVE}>Who we serve</Link>
              </li>
              <li>
                <Link to={ROUTES.FOR_AGENCIES}>For agencies</Link>
              </li>
              <li>
                <Link to={ROUTES.PRICING}>Pricing</Link>
              </li>
              {token && (
                <li>
                  <Link to={ROUTES.SETTINGS}>Settings</Link>
                </li>
              )}
              {token && isUserPlanActive && (
                <li>
                  <Link to={ROUTES.LEADS}>Leads</Link>
                </li>
              )}
              {userRole && userRole[0] === ROLES.ADMIN && (
                <li>
                  <Link to={ROUTES.ADMIN_PAGE}>Admin page</Link>
                </li>
              )}
              <li>
                <Link to={ROUTES.SUPPORT}>Support</Link>
              </li>
              {token ? (
                <li>
                  <button
                    className={styles.mobileLoginBtn}
                    onClick={handleLogoutMobileScreen}
                  >
                    Logout
                  </button>
                </li>
              ) : (
                <li>
                  <button
                    className={styles.mobileLoginBtn}
                    onClick={handleLoginMobileScreen}
                  >
                    Login
                  </button>
                </li>
              )}
              <li>
                <Link to={token && isUserPlanActive ? ROUTES.FORMS : ""}>
                  <button
                    onClick={
                      token && !isUserPlanActive
                        ? () => dispatch(setIsShowPricingModalAC(true))
                        : !token && !isUserPlanActive
                        ? handleRegisterMobileScreen
                        : () => {}
                    }
                  >
                    {isUserPlanActive ? "Forms" : "Get started"}
                  </button>
                </Link>
              </li>
            </ul>
          )}
        </div>
        {isWhoWeServeListOpen && (
          <div className={styles.whoWeServeListWrapper} ref={whoWeServeListRef}>
            <WhoWeServeList setIsWhoWeServeListOpen={setIsWhoWeServeListOpen} />
          </div>
        )}
      </div>
      <PricingModal />
      <LoginModal />
      <RegisterModal />
    </>
  );
};

export default Header;
