import Header from "src/pages/homePage/header/header";
import styles from "./../article.module.scss";
import Footer from "src/pages/homePage/footer/footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const HowToIntegrateWorkiz = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Support - How to intergade workiz</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link
          rel="canonical"
          href="https://leadquiz.com/support/how-to-integrate-workiz"
        />
        <meta
          property="og:title"
          content="LeadQuiz | Support - How to intergade workiz"
        />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://leadquiz.com/support/how-to-integrate-workiz"
        />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <h1>How to Integrate LeadQuiz with Workiz through Zapier</h1>
        <p>
          Integrating LeadQuiz with Workiz via Zapier allows you to automate the
          process of managing leads, seamlessly sending form submissions
          directly into your Workiz CRM. This eliminates manual data entry,
          saving you time and ensuring your leads are properly organized. Follow
          the steps below to set up the integration.
        </p>

        <b className={styles.step}>Step 1: Set Up Your LeadQuiz Form</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Create or open an existing form</b> in your LeadQuiz account.
          </li>
          <ul className={styles.subUl}>
            <li>
              Make sure the form is fully set up with all the necessary fields.
            </li>
            <li>
              You’ll use this form as the trigger for your Zapier integration.
            </li>
          </ul>
        </ul>

        <b className={styles.step}>Step 2: Log in to Zapier</b>
        <ul className={styles.numericUl}>
          <li>
            Go to{" "}
            <a href="https:/zapier.com/" target="_blank">
              Zapier.com
            </a>{" "}
            and log in to your account, or create a new one if you don’t have an
            account.
          </li>
          <li>
            From the dashboard, click "<b>Create Zap</b>" to begin setting up
            the automation.
          </li>
        </ul>

        <b className={styles.step}>Step 3: Set Up the Trigger (LeadQuiz)</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Choose LeadQuiz as the Trigger App:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              In the <b>Choose App & Event</b> search bar, type "LeadQuiz."
            </li>
            <li>
              Select <b>LeadQuiz</b> as the trigger app.
            </li>
          </ul>
          <li>
            <b>Select the Trigger Event:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Choose <b>New Form Submission</b> as the event that triggers the
              zap.
            </li>
            <li>
              This event ensures that every time someone submits your LeadQuiz
              form, Zapier will automatically push the data to Workiz.
            </li>
          </ul>
          <li>
            <b>Connect Your LeadQuiz Account:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              If this is your first time connecting LeadQuiz to Zapier, follow
              the prompts to log in and authorize the connection.
            </li>
            <li>
              Once connected, choose the form you want to use for the
              integration.
            </li>
          </ul>
          <li>
            <b>Test the Trigger:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Zapier will pull in recent form submissions to use as sample data
              for testing. This helps ensure the integration is properly set up
              before going live.
            </li>
          </ul>
        </ul>

        <b className={styles.step}>Step 4: Set Up the Action (Workiz)</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Choose Workiz as the Action App:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              In the <b>Choose App & Event</b> search bar, type "Workiz."
            </li>
            <li>
              Select <b>Workiz</b> as the action app.
            </li>
          </ul>
          <li>
            <b>Select the Action Event:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Choose the specific action you want to occur in Workiz when a new
              form submission is received.
            </li>
            <ul className={styles.subUl}>
              <li>
                Example actions include <b>Create Client</b>, <b>Create Job</b>,
                or <b>Create Lead</b>.
              </li>
            </ul>
          </ul>
          <li>
            <b>Connect Your Workiz Account:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              If this is your first time connecting Workiz to Zapier, you’ll
              need to log in to your Workiz account and authorize the
              connection.
            </li>
          </ul>
          <li>
            <b>Map the Fields:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Once your accounts are connected, Zapier will prompt you to map
              the form fields from LeadQuiz to the appropriate fields in Workiz.
            </li>
            <ul className={styles.subUl}>
              <li>For example:</li>
              <ul className={styles.subUl}>
                <li>
                  <b>Full Name</b> in LeadQuiz maps to <b>Client Name</b> in
                  Workiz.
                </li>
                <li>
                  <b>Email Address</b> and <b>Phone Number</b> map to their
                  respective fields in Workiz.
                </li>
              </ul>
            </ul>
            <li>
              Ensure all necessary fields are correctly matched so the data
              flows seamlessly into Workiz.
            </li>
          </ul>
          <li>
            <b>Test the Action:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Run a test to ensure that data from LeadQuiz is being successfully
              sent to Workiz.
            </li>
            <li>
              Check your Workiz account to verify that the new client or lead
              has been created with the test data.
            </li>
          </ul>
        </ul>

        <b className={styles.step}>Step 5: Turn on the Zap</b>
        <ul className={styles.numericUl}>
          <li>
            Once you’ve tested the zap and everything is working correctly,
            click "<b>Turn on Zap</b>" to activate the integration.
          </li>
          <li>
            Now, every time a form submission is received via LeadQuiz, the data
            will automatically be sent to Workiz.
          </li>
        </ul>

        <b className={styles.step}>
          Step 6: Monitor and Manage the Integration
        </b>
        <ul>
          <li>
            You can monitor the zap’s activity from the Zapier dashboard,
            checking for any issues or errors.
          </li>
          <li>
            If needed, you can modify the zap at any time to refine your
            workflow or add more functionality.
          </li>
        </ul>

        <b className={styles.step}>Tips for Success:</b>
        <ul>
          <li>
            <b>Custom Fields</b>: If you’re using custom fields in your LeadQuiz
            form, make sure to map them accurately to the corresponding fields
            in Workiz.
          </li>
          <li>
            <b>Field Validation</b>: Ensure that required fields in Workiz, such
            as contact information, are properly mapped from LeadQuiz to avoid
            missing data.
          </li>
          <li>
            <b>Zapier Plan</b>: Keep an eye on your Zapier plan’s usage limits,
            as higher volumes of form submissions may require a higher-tier
            plan.
          </li>
        </ul>
      </div>
      <div className={styles.footerWrap}>
        <Footer />
      </div>
    </div>
  );
};

export default HowToIntegrateWorkiz;
