import Input from "src/_elements/input/input";
import styles from "./googleAdsModal.module.scss";
import { useEffect, useState } from "react";
import Modal from "src/_elements/modal/modal";
import { patchGoogleAdsIdApi, postGoogleAdsIdApi } from "src/_utils/api/api";

const GoogleAdsModal: React.FC<any> = ({
  isShow,
  handleClose,
  item,
  setIsLoading,
  isEdit,
}) => {
  const [displayName, setDisplayName] = useState("");
  const [conversionId, setConversionId] = useState("");
  const [conversionLabel, setConversionLabel] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isEdit) {
      setDisplayName(item.displayName);
      setConversionId(item.conversionId);
      setConversionLabel(item.conversionLabel);
    }
  }, [isEdit, item]);

  const handleSave = () => {
    if (displayName === "" || conversionId === "" || conversionLabel === "") {
      setErrorMessage("Please fill in all fields");
    } else {
      if (!isEdit) {
        const payload = {
          displayName: displayName,
          conversionId: conversionId,
          conversionLabel: conversionLabel,
        };

        postGoogleAdsIdApi(payload).then(() => {
          setIsLoading(true);
          handleClose();
        });
      } else {
        const payload = {
          displayName: displayName,
          conversionId: conversionId,
          conversionLabel: conversionLabel,
        };

        patchGoogleAdsIdApi(payload, item.id).then(() => {
          setIsLoading(true);
          handleClose();
        });
      }
    }
  };

  return (
    <Modal className={styles.wrapper} isShow={isShow} onClose={handleClose}>
      <div className={styles.content}>
        <p className={styles.title}>
          {isEdit ? "Edit google ads" : "Add new google ads"}
        </p>
        <div className={styles.inputs}>
          <Input
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder="Display name"
            label="Display name"
          />
          <Input
            value={conversionId}
            onChange={(e) => setConversionId(e.target.value)}
            placeholder="Conversion id"
            label="Conversion id"
          />
          <Input
            value={conversionLabel}
            onChange={(e) => setConversionLabel(e.target.value)}
            placeholder="Conversion label"
            label="Conversion label"
          />
        </div>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        <div className={styles.btnWrap}>
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </Modal>
  );
};

export default GoogleAdsModal;
