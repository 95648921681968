import { paidCallMarkAsCompletedApi } from "src/_utils/api/api";
import styles from "./paidCallUserItem.module.scss";
import ActionModal from "src/_elements/actionModal/actionModal";
import { useState } from "react";

const PaidCallUserItem: React.FC<any> = ({ data, setIsLoading }: any) => {
  const [isShowCancelCallModal, setIsShowCancelCallModal] = useState(false);

  const handleCLoseCancelCallModal = () => {
    setIsShowCancelCallModal(false);
  };

  const handleCancelOnboardingCall = () => {
    setIsLoading(true);
    const payload = {
      paidCallIds: [data.id],
    };
    paidCallMarkAsCompletedApi(payload).then(() => {
      setIsLoading(false);
      setIsShowCancelCallModal(false);
    });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <p>
            User name:
            <br />
            <span>{data.user.name}</span>
          </p>
          <p>
            User email:
            <br />
            <span>{data.user.email}</span>
          </p>
        </div>
        <div className={styles.btnWrap}>
          {data.active ? (
            <button onClick={() => setIsShowCancelCallModal(true)}>
              Cancel onboarding call / Mark as ended
            </button>
          ) : (
            <p className={styles.endedLabel}>Onboarding call ended</p>
          )}
        </div>
      </div>
      <ActionModal
        title={`Mark call as ended for ${data.user.name}?`}
        subtitle="If you have ended a call, or want to cancel a call, this action will mark the call as ended."
        isShowModal={isShowCancelCallModal}
        handleCloseModal={handleCLoseCancelCallModal}
        handleAction={handleCancelOnboardingCall}
        actionButtonText="Mark as ended"
      />
    </>
  );
};

export default PaidCallUserItem;
