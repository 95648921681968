import ROUTES from "src/_utils/routes/routes";
import Footer from "../homePage/footer/footer";
import Header from "../homePage/header/header";
import styles from "./support.module.scss";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const Support = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Support</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link rel="canonical" href="https://leadquiz.com/support" />
        <meta property="og:title" content="LeadQuiz | Support" />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://leadquiz.com/support" />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <h1>FAQ:</h1>
        <ul className={styles.faqWrap}>
          <li>
            <Link to={ROUTES.HOW_TO_CREATE_FORM}>
              How to <b>Create a Form</b> with <b>LeadQuiz</b>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.HOW_TO_ADD_PRIVACY_POLICY_AND_BUSINESS_INFO}>
              How to <b>Add a Privacy Policy</b> and <b>Business Information</b>{" "}
              to Your
              <b>LeadQuiz</b> Form
            </Link>
          </li>
          <li>
            <Link to={ROUTES.HOW_TO_INTEGRATE_JOBBER}>
              How to <b>Integrate LeadQuiz</b> with <b>Jobber</b> through{" "}
              <b>Zapier</b>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.HOW_TO_INTEGRATE_WORKIZ}>
              How to <b>Integrate LeadQuiz</b> with <b>Workiz</b> through{" "}
              <b>Zapier</b>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.HOW_TO_INTEGRATE_LEAD_CONNECTOR}>
              How to <b>Integrate LeadQuiz</b> with <b>LeadConnector</b> through{" "}
              <b>Zapier</b>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.HOW_TO_INTEGRATE_BUILDER_PRIME}>
              How to <b>Integrate LeadQuiz</b> with <b>Builder Prime</b> through{" "}
              <b>Zapier</b>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.HOW_TO_TRACK_FACEBOOK_AD}>
              How to <b>Track Facebook Ad Conversions (FB Pixel)</b> with{" "}
              <b>LeadQuiz</b>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.HOW_TO_TRACK_GOOGLE_ADS}>
              How to <b>Track Google Ads Conversions</b> with <b>LeadQuiz</b>
            </Link>
          </li>
        </ul>
      </div>
      <div className={styles.footerWrap}>
        <Footer />
      </div>
    </div>
  );
};

export default Support;
