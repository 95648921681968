import { useEffect } from "react";
import Benefits from "./benefits/benefits";
import Footer from "./footer/footer";
import GetUp from "./getUp/getUp";
import Header from "./header/header";
import styles from "./homePage.module.scss";
import HowToMakeYourQuiz from "./howToMakeYourQuiz/howToMakeYourQuiz";
import Integrates from "./integrates/integrates";
import OurCustomers from "./ourCustomers/ourCustomers";
import Payments from "./payments/payments";
import Top from "./top/top";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { setIsShowPricingModalAC } from "src/store/actions";

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    const isFirstLogin = localStorage.getItem("isFirstLogin");
    isFirstLogin === "true" && dispatch(setIsShowPricingModalAC(true));
    localStorage.removeItem("isFirstLogin");
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link rel="canonical" href="https://leadquiz.com/" />
        <meta property="og:title" content="LeadQuiz" />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://leadquiz.com/" />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form"
        />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <div className={styles.topOuterBg}>
        <div className={styles.wrapper}>
          <Top />
        </div>
      </div>
      <div className={styles.wrapper}>
        <Integrates />
        <Benefits />
        <HowToMakeYourQuiz />
        <OurCustomers />
        <div className={styles.paymentsWrap}>
          <Payments />
        </div>
        <GetUp />
        <div className={styles.footerWrapper}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
