import Header from "src/pages/homePage/header/header";
import styles from "./../article.module.scss";
import Footer from "src/pages/homePage/footer/footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const HowToIntegrateLeadConnector = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Support - How to intergade connector</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link
          rel="canonical"
          href="https://leadquiz.com/support/how-to-integrate-lead-connector"
        />
        <meta
          property="og:title"
          content="LeadQuiz | Support - How to intergade connector"
        />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://leadquiz.com/support/how-to-integrate-lead-connector"
        />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <h1>How to Integrate LeadQuiz with LeadConnector through Zapier</h1>
        <p>
          Integrating LeadQuiz with LeadConnector via Zapier allows you to
          automate lead management by sending form submissions directly to
          LeadConnector. This saves time and helps you manage your leads
          efficiently without manual data entry. Follow these steps to set up
          the integration.
        </p>

        <b className={styles.step}>Step 1: Set Up Your LeadQuiz Form</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Create or open an existing form</b> in your LeadQuiz account.
          </li>
          <ul className={styles.subUl}>
            <li>
              Ensure that all necessary fields (such as name, email, phone
              number) are included in the form.
            </li>
            <li>
              This form will serve as the trigger for your Zapier integration.
            </li>
          </ul>
        </ul>

        <b className={styles.step}>Step 2: Log in to Zapier</b>
        <ul className={styles.numericUl}>
          <li>
            Visit{" "}
            <a href="https:/zapier.com/" target="_blank">
              Zapier.com
            </a>{" "}
            and log in to your account. If you don’t have an account, sign up
            for free.
          </li>
          <li>
            Once logged in, click "<b>Create Zap</b>" to start creating your
            automation.
          </li>
        </ul>

        <b className={styles.step}>Step 3: Set Up the Trigger (LeadQuiz)</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Choose LeadQuiz as the Trigger App:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              In the <b>Choose App & Event</b> search bar, type "LeadQuiz."
            </li>
            <li>
              Select <b>LeadQuiz</b> as your trigger app.
            </li>
          </ul>
          <li>
            <b>Select the Trigger Event:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Choose <b>New Form Submission</b> as the event to trigger the zap.
              This ensures the zap runs every time a form submission is
              received.
            </li>
          </ul>
          <li>
            <b>Connect Your LeadQuiz Account:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              If this is your first time using LeadQuiz with Zapier, you'll need
              to connect your LeadQuiz account. Follow the on-screen prompts to
              log in and authorize Zapier to access LeadQuiz.
            </li>
          </ul>
          <li>
            <b>Select the Form:</b>
            <ul className={styles.subUl}>
              <li>
                Once connected, choose the form from LeadQuiz that will trigger
                the zap when a submission is made.
              </li>
            </ul>
          </li>
          <li>
            <b>Test the Trigger:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Zapier will pull in recent submissions from the form to test the
              integration and ensure everything is working correctly.
            </li>
          </ul>
        </ul>

        <b className={styles.step}>Step 4: Set Up the Action (LeadConnector)</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Choose LeadConnector as the Action App:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              In the <b>Choose App & Event</b> search bar, type "LeadConnector."
            </li>
            <li>
              Select <b>LeadConnector</b> as your action app.
            </li>
          </ul>
          <li>
            <b>Select the Action Event:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Choose the action you want LeadConnector to perform when a lead is
              submitted through your form.
            </li>
            <ul className={styles.subUl}>
              <li>
                Example actions could be <b>Create/Update Contact</b>,{" "}
                <b>Create Opportunity</b>, or <b>Add to Campaign</b>.
              </li>
            </ul>
          </ul>
          <li>
            <b>Connect Your LeadConnector Account:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              If this is your first time connecting LeadConnector to Zapier,
              you’ll need to log in to LeadConnector and follow the
              authorization steps to allow Zapier access to your account.
            </li>
          </ul>
          <li>
            <b>Map the Fields:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Zapier will display a list of fields from LeadQuiz that you need
              to map to LeadConnector. Ensure that all form fields (such as
              name, phone number, and email) are correctly mapped to their
              corresponding fields in LeadConnector.
            </li>
            <ul className={styles.subUl}>
              <li>For example:</li>
              <ul className={styles.subUl}>
                <li>
                  <b>Full Name</b> from LeadQuiz → <b>Contact Name</b> in
                  LeadConnector.
                </li>
                <li>
                  <b>Email</b> from LeadQuiz → <b>Email Address</b> in
                  LeadConnector.
                </li>
                <li>
                  <b>Phone Number</b> from LeadQuiz → <b>Phone</b> in
                  LeadConnector.
                </li>
              </ul>
            </ul>
          </ul>
          <li>
            <b>Test the Action:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Once you’ve mapped the fields, test the action by sending a sample
              form submission to LeadConnector. Check your LeadConnector account
              to verify that the new contact or opportunity has been created
              correctly.
            </li>
          </ul>
        </ul>

        <b className={styles.step}>Step 5: Turn on the Zap</b>
        <ul className={styles.numericUl}>
          <li>
            After you’ve confirmed the integration is working, click "
            <b>Turn on Zap</b>" to activate the workflow.
          </li>
          <li>
            Going forward, every time a LeadQuiz form is submitted, the data
            will be sent directly to LeadConnector.
          </li>
        </ul>

        <b className={styles.step}>
          Step 6: Monitor and Adjust the Integration
        </b>
        <ul>
          <li>
            You can view and manage the zap from your Zapier dashboard, where
            you can monitor activity, troubleshoot any issues, or edit the zap
            to refine the workflow.
          </li>
        </ul>

        <b className={styles.step}>Tips for Success:</b>
        <ul>
          <li>
            <b>Custom Fields</b>: If your LeadQuiz form contains custom fields,
            make sure to map them correctly to corresponding fields in
            LeadConnector.
          </li>
          <li>
            <b>Zapier Usage</b>: Be aware of your Zapier plan limits to ensure
            you have enough zaps for high submission volumes.
          </li>
          <li>
            <b>Regular Testing</b>: Regularly test the zap to ensure the data is
            flowing correctly between LeadQuiz and LeadConnector, especially
            after making changes to the form.
          </li>
        </ul>
      </div>
      <div className={styles.footerWrap}>
        <Footer />
      </div>
    </div>
  );
};

export default HowToIntegrateLeadConnector;
