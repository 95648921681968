import React, { useState, useEffect } from "react";
import styles from "./animatedPopup.module.scss";

const AnimatedPopup: React.FC<any> = ({ isOpen, onClose, children }: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setTimeout(() => setIsAnimating(true), 100);
    } else {
      setIsAnimating(false);
      const timeout = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  if (!isVisible) return null;

  return (
    <div
      className={`${styles.overlay} ${isAnimating ? styles.showOverlay : styles.hideOverlay}`}
      onClick={onClose}
    >
      <div
        className={`${styles.modal} ${isAnimating ? styles.showModal : styles.hideModal}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button className={styles.closeButton} onClick={onClose}>
          ✕
        </button>
        {children}
      </div>
    </div>
  );
};

export default AnimatedPopup;
