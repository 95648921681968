import Header from "src/pages/homePage/header/header";
import styles from "./../article.module.scss";
import Footer from "src/pages/homePage/footer/footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const HowToAddPrivacyPolicyAndBusinessInfo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Support - How to add privacy policy and business info</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link
          rel="canonical"
          href="https://leadquiz.com/support/how-to-add-privacy-policy-and-business-info"
        />
        <meta
          property="og:title"
          content="LeadQuiz | Support - How to add privacy policy and business info"
        />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://leadquiz.com/support/how-to-add-privacy-policy-and-business-info"
        />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <h1>
          How to Add a Privacy Policy and Business Information to Your LeadQuiz
          Form
        </h1>
        <p>
          To build trust with potential customers and comply with regulations,
          it’s important to display your privacy policy and business information
          on your LeadQuiz form. Follow these simple steps to include this
          information on your forms.
        </p>

        <b className={styles.step}>Step 1: Access the Form Builder</b>
        <ul>
          <li>
            Log in to your LeadQuiz account at{" "}
            <a href="https://leadquiz.com/" target="_blank">
              leadquiz.com
            </a>
            .
          </li>
          <li>
            Navigate to the <b>Form Builder</b> from the main dashboard.
          </li>
          <li>Open the form you want to edit or start a new one.</li>
        </ul>

        <b className={styles.step}>Step 2: Add Your Privacy Policy Link</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Locate the Privacy Policy Field</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              In the form builder, go to the <b>Settings</b> tab.
            </li>
            <li>
              Scroll down to the section labeled <b>Privacy Policy</b>.
            </li>
          </ul>
          <li>
            <b>Enter the URL to Your Privacy Policy</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              If you already have a privacy policy page on your website, copy
              the URL.
            </li>
            <li>
              Paste the URL into the <b>Privacy Policy URL</b> field.
            </li>
            <ul className={styles.subUl}>
              <li>
                If you don’t have a privacy policy yet, you can create one using
                tools like Termly or Iubenda, or consult with a legal
                professional.
              </li>
            </ul>
          </ul>
          <li>
            <b>Display the Privacy Policy on the Form</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Once you’ve added the URL, a link to your Privacy Policy will
              automatically appear at the bottom of your form.
            </li>
            <li>
              You can customize the label to something like “Privacy Policy” or
              “Your Privacy Rights.”
            </li>
          </ul>
        </ul>

        <b className={styles.step}>
          Step 3: Add Business Information to Your Form
        </b>
        <p>
          It’s important to display your business details so potential customers
          know who they’re providing their information to.
        </p>
        <ul className={styles.numericUl}>
          <li>
            <b>Add a Business Information Section</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              In the form builder, add a <b>Section Header</b> field.
            </li>
            <li>Label this section something like "Business Information."</li>
          </ul>
          <li>
            <b>Enter Your Business Details</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Add a <b>Text Block</b> or <b>Paragraph Field</b> under the
              Business Information section.
            </li>
            <li>Fill in your business information, including:</li>
            <ul className={styles.subUl}>
              <li>
                <b>Business Name</b>: Your registered business name.
              </li>
              <li>
                <b>Contact Information</b>: Phone number and email address
              </li>
            </ul>
          </ul>
        </ul>

        <b className={styles.step}>Step 4: Preview and Publish the Form</b>
        <ul>
          <li>
            Once you’ve added your privacy policy and business information,
            preview the form to ensure everything appears correctly.
          </li>
          <li>
            Click <b>Publish</b> to make your form live.
          </li>
        </ul>
        <b className={styles.step}>Tips for Success</b>
        <ul>
          <li>
            <b>Clear and Visible</b>: Ensure that the privacy policy link is
            visible and easy to understand, as this builds trust.
          </li>
          <li>
            <b>Accurate Information</b>: Make sure your business information is
            up to date and accurate, as it helps establish credibility with
            potential leads.
          </li>
          <li>
            <b>Compliance</b>: Depending on your region, you may need to comply
            with specific privacy laws (e.g., GDPR, CCPA). Be sure your privacy
            policy covers necessary legal requirements.
          </li>
        </ul>
      </div>
      <div className={styles.footerWrap}>
        <Footer />
      </div>
    </div>
  );
};

export default HowToAddPrivacyPolicyAndBusinessInfo;
