import Header from "src/pages/homePage/header/header";
import styles from "./../article.module.scss";
import Footer from "src/pages/homePage/footer/footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const HowToIntegrateJobber = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Support - How to intergade jobber</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link
          rel="canonical"
          href="https://leadquiz.com/support/how-to-integrate-jobber"
        />
        <meta
          property="og:title"
          content="LeadQuiz | Support - How to intergade jobber"
        />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://leadquiz.com/support/how-to-integrate-jobber"
        />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <h1>How to Integrate LeadQuiz with Jobber through Zapier</h1>
        <p>
          Integrating your LeadQuiz form with Jobber via Zapier allows you to
          automate workflows and streamline lead management. When a lead submits
          a form, their information can be automatically sent to Jobber, helping
          you manage customer data without manual entry. Follow the steps below
          to set up this integration.
        </p>

        <b className={styles.step}>Step 1: Set Up Your LeadQuiz Form</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Create or open an existing form</b> in your LeadQuiz account.
          </li>
          <ul className={styles.subUl}>
            <li>Make sure the form is complete and ready for submission.</li>
            <li>
              You’ll be using this form to trigger the integration with Jobber.
            </li>
          </ul>
        </ul>

        <b className={styles.step}>Step 2: Sign in to Zapier</b>
        <ul className={styles.numericUl}>
          <li>
            Go to{" "}
            <a href="https:/zapier.com/" target="_blank">
              Zapier.com
            </a>{" "}
            and either <b>log in</b> to your account or create a new one if you
            don’t have an account yet.
          </li>
          <li>
            In your Zapier dashboard, click on <b>"Create Zap"</b> to start
            setting up the automation.
          </li>
        </ul>

        <b className={styles.step}>Step 3: Set Up the Trigger (LeadQuiz)</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Choose LeadQuiz as the Trigger App:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              In the <b>Choose App & Event</b> search bar, type "LeadQuiz."
            </li>
            <li>
              Select <b>LeadQuiz</b> as your trigger app.
            </li>
          </ul>
          <li>
            <b>Select the Trigger Event:</b>
            <ul className={styles.subUl}>
              <li>
                Choose <b>New Form Submission</b> as the event that will start
                the automation.
              </li>
              <li>
                This ensures that every time someone submits your LeadQuiz form,
                Zapier will automatically run the zap and send the data to
                Jobber.
              </li>
            </ul>
          </li>
          <li>
            <b>Connect Your LeadQuiz Account:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              If this is your first time connecting LeadQuiz to Zapier, you’ll
              need to enter your LeadQuiz credentials to link your account.
            </li>
            <li>
              Follow the prompts to authorize Zapier to access your LeadQuiz
              account.
            </li>
          </ul>
          <li>
            <b>Select the Form:</b>
            <ul className={styles.subUl}>
              <li>
                Once connected, choose the specific form you want to use as the
                trigger for this zap.
              </li>
            </ul>
          </li>
          <li>
            <b>Test the Trigger:</b>
            <ul className={styles.subUl}>
              <li>
                Zapier will pull sample data from a recent form submission. You
                can use this data to ensure the integration works properly
                before going live.
              </li>
            </ul>
          </li>
        </ul>

        <b className={styles.step}>Step 4: Set Up the Action (Jobber)</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Choose Jobber as the Action App:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              In the <b>Choose App & Event</b> search bar, type "Jobber."
            </li>
            <li>
              Select <b>Jobber</b> as your action app.
            </li>
          </ul>
          <li>
            <b>Select the Action Event:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Choose the specific action you want to occur in Jobber when a lead
              submits the form.
            </li>
            <ul className={styles.subUl}>
              <li>
                Example actions include <b>Create Client</b>, <b>Create Job</b>,
                or <b>Create Quote</b>.
              </li>
            </ul>
          </ul>
          <li>
            <b>Connect Your Jobber Account:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              If this is your first time connecting Jobber to Zapier, you’ll
              need to authorize Zapier to access your Jobber account.
            </li>
            <li>
              Log in to your Jobber account and follow the steps to complete the
              connection.
            </li>
          </ul>
          <li>
            <b>Map the Fields:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              Zapier will ask you to map the form fields from LeadQuiz to the
              corresponding fields in Jobber.
            </li>
            <li>For example:</li>
            <ul className={styles.subUl}>
              <li>
                <b>Full Name</b> in LeadQuiz maps to <b>Client Name</b> in
                Jobber.
              </li>
              <li>
                <b>Email</b> and <b>Phone Number</b> fields map to their
                respective Jobber fields.
              </li>
            </ul>
          </ul>
          <li>
            <b>Test the Action:</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              After mapping the fields, run a test to ensure that data from
              LeadQuiz is being correctly sent to Jobber.
            </li>
            <li>
              Check your Jobber account to confirm that a new client, job, or
              quote has been created.
            </li>
          </ul>
        </ul>

        <b className={styles.step}>Step 5: Turn on the Zap</b>
        <ul className={styles.numericUl}>
          <li>
            Once everything is set up and tested, click "<b>Turn on Zap</b>" to
            activate the integration.
          </li>
          <li>
            From now on, whenever a form is submitted through LeadQuiz, Zapier
            will automatically send the information to Jobber, creating a
            seamless workflow for managing leads.
          </li>
        </ul>

        <b className={styles.step}>Step 6: Monitor the Integration</b>
        <ul>
          <li>
            In your Zapier dashboard, you can monitor the zap’s activity and
            review any errors or issues that might occur.
          </li>
          <li>
            You can also edit or update the zap anytime to refine your workflow.
          </li>
        </ul>

        <b className={styles.step}>Tips for Success:</b>
        <ul>
          <li>
            <b>Custom Fields</b>: If you’ve added custom fields in your LeadQuiz
            form, be sure to map them correctly to the corresponding fields in
            Jobber.
          </li>
          <li>
            <b>Zap Limits</b>: Depending on your Zapier plan, there may be
            limits on the number of zaps you can run per month, so monitor your
            zap usage.
          </li>
          <li>
            <b>Test Regularly</b>: Make sure to test your zaps regularly to
            ensure everything is functioning as expected.
          </li>
        </ul>
      </div>
      <div className={styles.footerWrap}>
        <Footer />
      </div>
    </div>
  );
};

export default HowToIntegrateJobber;
