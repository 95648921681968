import Header from "../homePage/header/header";
import styles from "./adminPage.module.scss";
import { useEffect, useState } from "react";
import Plan from "./plan/plan";
import ConfigPlanModal from "./configPlanModal/configPlanModal";
import {
  getFormTemplatesApi,
  getPaidCallListApi,
  getPlansListApi,
} from "src/_utils/api/api";
import { IPlanData } from "../interfaces/interfaces";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";
import { getUserRole } from "src/_utils/userRoles/getUserRole";
import ROLES from "src/_utils/userRoles/userRoles";
import AddSubmissionsModal from "./addSubmissionsModal/addSubmissionsModal";
import PersonalPlans from "./personalPlans/personalPlans";
import FormTemplates from "./formTemplates/formTemplates";
import Pagination from "../leads/pagination/pagination";
import PaidCallList from "./paidCallList/paidCallList";

const AdminPage = () => {
  const token = localStorage.getItem("token");

  const formTemplatesPageSize = 4;
  const paidCallPageSize = 4;
  const plansPageSize = 3;

  const navigate = useNavigate();
  const userRole = getUserRole(token);

  const [isLoading, setIsLoading] = useState(false);
  const [publicPlansList, setPublicPlansList] = useState<IPlanData[] | null>(
    null
  );
  const [paidCallUsersList, setPaidCallUsersList] = useState([]);
  const [personalPlansList, setPersonalPlansList] = useState<
    IPlanData[] | null
  >(null);
  const [formTemplatesList, setFormTemplatesList] = useState(null);
  const [isShowAddSubmissionsModal, setIsShowAddSubmissionsModal] =
    useState(false);
  const [isShowPlanEditModal, setIsShowPlanEditModal] = useState(false);
  const [selectedPlanData, setSelectedPlanData] = useState<null | IPlanData>(
    null
  );
  const [isPersonalPlan, setIsPersonalPlan] = useState(false);

  const [currentPublicPlansPage, setCurrentPublicPlansPage] = useState(1);
  const [totalPublicPlansPages, setTotalPublicPlansPages] = useState(1);

  const [currentPersonalPlansPage, setCurrentPersonalPlansPage] = useState(1);
  const [totalPersonalPlansPages, setTotalPersonalPlansPages] = useState(1);

  const [currentFormTemplatesPage, setCurrentFormTemplatesPage] = useState(1);
  const [totalFormTemplatesPages, setTotalFormTemplatesPages] = useState(1);

  const [currentPaidCallPage, setCurrentPaidCallPage] = useState(1);
  const [totalPaidCallPages, setTotalPaidCallPages] = useState(1);

  const [searchEmailValue, setSearchEmailValue] = useState("");
  const [debouncedSearchEmailValue, setDebouncedSearchEmailValue] =
    useState("");

  const [isShowOnlyActiveCalls, setIsShowOnlyActiveCalls] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const payload = {
      onlyActive: true,
      onlyPublic: true,
      pageSize: plansPageSize,
      page: currentPublicPlansPage,
    };

    getPlansListApi(payload)
      .then((res) => {
        setPublicPlansList(res.data.data.plans);
        setTotalPublicPlansPages(
          Math.ceil(res.data.data.total / plansPageSize)
        );
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, [currentPublicPlansPage, isLoading]);

  useEffect(() => {
    const payload = {
      onlyActive: true,
      onlyPersonal: true,
      pageSize: plansPageSize,
      page: currentPersonalPlansPage,
    };

    getPlansListApi(payload)
      .then((res) => {
        setPersonalPlansList(res.data.data.plans);
        setTotalPersonalPlansPages(
          Math.ceil(res.data.data.total / plansPageSize)
        );
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, [currentPersonalPlansPage, isLoading]);

  useEffect(() => {
    const payload = {
      pageSize: formTemplatesPageSize,
      page: currentFormTemplatesPage,
    };

    getFormTemplatesApi(payload).then((res) => {
      setFormTemplatesList(res.data.data.formTemplates);
      setTotalFormTemplatesPages(
        Math.ceil(res.data.data.total / formTemplatesPageSize)
      );
    });
  }, [currentFormTemplatesPage]);

  useEffect(() => {
    let payload;

    if (debouncedSearchEmailValue && isShowOnlyActiveCalls) {
      payload = {
        pageSize: paidCallPageSize,
        page: currentPaidCallPage,
        email: debouncedSearchEmailValue,
        active: isShowOnlyActiveCalls,
      };
    } else if (debouncedSearchEmailValue) {
      payload = {
        pageSize: paidCallPageSize,
        page: currentPaidCallPage,
        email: debouncedSearchEmailValue,
      };
    } else if (isShowOnlyActiveCalls) {
      payload = {
        pageSize: paidCallPageSize,
        page: currentPaidCallPage,
        active: isShowOnlyActiveCalls,
      };
    } else {
      payload = {
        pageSize: paidCallPageSize,
        page: currentPaidCallPage,
      };
    }

    getPaidCallListApi(payload).then((res) => {
      setPaidCallUsersList(res.data.data.list);
      setTotalPaidCallPages(Math.ceil(res.data.data.total / paidCallPageSize));
      setIsLoading(false);
    });
  }, [
    currentPaidCallPage,
    isLoading,
    debouncedSearchEmailValue,
    isShowOnlyActiveCalls,
  ]);

  useEffect(() => {
    if (!token || userRole[0] !== ROLES.ADMIN) {
      navigate(ROUTES.HOME);
    }
  }, [token]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchEmailValue(searchEmailValue);
    }, 500);

    return () => clearTimeout(timerId);
  }, [searchEmailValue]);

  const handlePublicPlansPageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPublicPlansPages) {
      setCurrentPublicPlansPage(newPage);
    }
  };

  const handlePersonalPlansPageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPersonalPlansPages) {
      setCurrentPersonalPlansPage(newPage);
    }
  };

  const handleFormTemplatesPageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalFormTemplatesPages) {
      setCurrentFormTemplatesPage(newPage);
    }
  };

  const handlePaidCallPageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPaidCallPages) {
      setCurrentPaidCallPage(newPage);
    }
  };

  const handleAddNewPlan = () => {
    setSelectedPlanData(null);
    setIsShowPlanEditModal(true);
  };

  const handleAddNewFormTemplate = () => {
    navigate(ROUTES.CREATE_FORM_TEMPLTE);
  };

  const publicPlans = publicPlansList?.filter(
    (plan) => plan.userId === undefined && plan.isPublic
  );

  const sortedByDatePublicPlans =
    publicPlans &&
    publicPlans.sort(
      //@ts-ignore
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

  const personalPlans = personalPlansList?.filter(
    (plan) => plan.userId !== undefined && !plan.isPublic
  );

  return (
    <>
      <div className={styles.outerWrapper}>
        <Helmet>
          <title>LeadQuiz | Admin page</title>
        </Helmet>
        <Header />
        <div className={styles.sectionWrapper}>
          <p className={styles.title}>Public plans:</p>
          <div className={styles.wrapper}>
            <button className={styles.button} onClick={handleAddNewPlan}>
              <p>Add new plan</p>
            </button>
            {sortedByDatePublicPlans &&
              sortedByDatePublicPlans.map((item, index) => (
                <Plan
                  key={index}
                  planData={item}
                  setIsShowAddSubmissionsModal={setIsShowAddSubmissionsModal}
                  setIsShowPlanEditModal={setIsShowPlanEditModal}
                  setSelectedPlanData={setSelectedPlanData}
                  setIsLoading={setIsLoading}
                />
              ))}
          </div>
          <div className={styles.paginationWrapper}>
            <Pagination
              handlePageChange={handlePublicPlansPageChange}
              currentPage={currentPublicPlansPage}
              totalPages={totalPublicPlansPages}
            />
          </div>
        </div>
        <div>
          <p className={styles.title}>Enterprise plans:</p>
          <PersonalPlans
            handleAddNewPlan={handleAddNewPlan}
            setIsPersonalPlan={setIsPersonalPlan}
            personalPlans={personalPlans}
            setIsShowAddSubmissionsModal={setIsShowAddSubmissionsModal}
            setIsShowPlanEditModal={setIsShowPlanEditModal}
            setSelectedPlanData={setSelectedPlanData}
            setIsLoading={setIsLoading}
          />
          <div className={styles.paginationWrapper}>
            <Pagination
              handlePageChange={handlePersonalPlansPageChange}
              currentPage={currentPersonalPlansPage}
              totalPages={totalPersonalPlansPages}
            />
          </div>
        </div>

        <div className={styles.elementsWrap}>
          <p className={styles.title}>Form templates:</p>
          <FormTemplates
            handleAddNewFormTemplate={handleAddNewFormTemplate}
            formTemplatesList={formTemplatesList}
          />
        </div>
        <div className={styles.paginationWrapper}>
          <Pagination
            handlePageChange={handleFormTemplatesPageChange}
            currentPage={currentFormTemplatesPage}
            totalPages={totalFormTemplatesPages}
          />
        </div>

        <div className={styles.elementsWrap}>
          <p className={styles.title}>Paid call users:</p>
          <div className={styles.searchWrap}>
            <div>
              <p>Search by user email:</p>
              <input
                type="text"
                value={searchEmailValue}
                onChange={(e) => setSearchEmailValue(e.target.value)}
              />
            </div>
            <div className={styles.checkboxWrap}>
              <p>Show only active:</p>
              <input
                type="checkbox"
                checked={isShowOnlyActiveCalls}
                onChange={(e) => setIsShowOnlyActiveCalls(e.target.checked)}
              />
            </div>
          </div>
          <PaidCallList
            paidCallUsersList={paidCallUsersList}
            setIsLoading={setIsLoading}
          />
        </div>
        <div className={styles.paginationWrapper}>
          <Pagination
            handlePageChange={handlePaidCallPageChange}
            currentPage={currentPaidCallPage}
            totalPages={totalPaidCallPages}
          />
        </div>
      </div>
      <AddSubmissionsModal
        isShowAddSubmissionsModal={isShowAddSubmissionsModal}
        handleCloseModal={() => setIsShowAddSubmissionsModal(false)}
        selectedPlanData={selectedPlanData}
      />
      <ConfigPlanModal
        isShowPlanEditModal={isShowPlanEditModal}
        handleCloseModal={() => setIsShowPlanEditModal(false)}
        selectedPlanData={selectedPlanData}
        isPersonalPlan={isPersonalPlan}
        setIsPersonalPlan={setIsPersonalPlan}
        setIsPlansLoading={setIsLoading}
      />
    </>
  );
};

export default AdminPage;
