import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Benefits from "../homePage/benefits/benefits";
import Footer from "../homePage/footer/footer";
import Header from "../homePage/header/header";
import Integrates from "../homePage/integrates/integrates";
import styles from "./howItWorks.module.scss";
import Top from "../top/top";
import ContentSection from "../whoWeServe/tradePage/contentSection/contentSection";
import graphArrowIcon from "src/assets/howItWorks/graphArrow.svg";
import easyToUseIcon from "src/assets/howItWorks/easyToUse.svg";
import homeIcon from "src/assets/howItWorks/home.svg";
import automatePeopleIcon from "src/assets/howItWorks/automatePeople.svg";
import diamondIcon from "src/assets/howItWorks/diamond.svg";
import arrowUpIcon from "src/assets/arrowUp.svg";
import phoneAndLaptopImg from "src/assets/howItWorks/phoneAndLaptop.png";
import leadGenerationImg from "src/assets/howItWorks/leadGeneration.svg";
import macStudioImg from "src/assets/howItWorks/macStudio.png";
import automateImg from "src/assets/howItWorks/automate.svg";
import samsungTvImg from "src/assets/howItWorks/samsungTv.png";
import rocketImg from "src/assets/howItWorks/rocket.svg";
import ActionContentFooter from "../forAgencies/actionContentFooter/actionContentFooter";

function HowItWorks() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | How it works</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link rel="canonical" href="https://leadquiz.com/how-it-works" />
        <meta property="og:title" content="LeadQuiz | How it works" />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://leadquiz.com/how-it-works" />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <Top />
      <div className={styles.wrapper}>
        <Integrates />
        <Benefits />
        <div className={styles.contentWrap}>
          <ContentSection
            icon={graphArrowIcon}
            title="Maximize Results from Facebook & Google Ads"
            img={phoneAndLaptopImg}
            description="LeadQuiz integrates seamlessly with Facebook Ads and Google Ads, helping you capture leads directly from these platforms. Whether promoting seasonal offers or core services, our customizable forms convert clicks into qualified leads, boosting your ad performance."
            title1="Customizable Forms"
            title2="Higher Conversion Rates"
            subtitle1="Tailor quizzes and forms to match your brand and service offering."
            subtitle2="Engage potential customers with relevant, interactive content that
          drives results."
          />
          <ContentSection
            isReversed
            icon={easyToUseIcon}
            title="Simplify Your Lead Generation with LeadQuiz"
            img={leadGenerationImg}
            description="LeadQuiz is built specifically to help home service businesses generate high-quality leads, especially through Facebook and Google Ads. Whether you offer HVAC, plumbing, landscaping, or any other home service, our platform enables you to create customized quizzes and forms that attract the right audience and convert them into paying customers directly from your advertising campaigns."
          />
          <ContentSection
            icon={homeIcon}
            title="Built for Your Home Service Business"
            img={macStudioImg}
            description="LeadQuiz is built with home service providers in mind. Our intuitive platform lets you create branded lead forms without needing technical skills. Simply customize it to your specific services and start attracting leads that match your business goals."
            title1="Easy-to-Use Interface"
            title2="Tailored for everything"
            subtitle1="No coding needed—just drag, drop, and customize."
            subtitle2="Designed to meet the unique needs."
          />
          <ContentSection
            isReversed
            icon={automatePeopleIcon}
            title="Automate and Streamline Your Lead Management"
            img={automateImg}
            description="LeadQuiz integrates effortlessly with the CRMs you already use, like Jobber, HubSpot, and others. Our Zapier integration automates lead data flow, so you can focus on delivering excellent service instead of dealing with manual data entry. This allows you to stay organized, follow up quickly, and never miss an opportunity."
          />
          <ContentSection
            icon={diamondIcon}
            title="Capture High-Quality Leads Every Time"
            img={samsungTvImg}
            description="Not all leads are created equal. LeadQuiz’s smart forms filter out low-quality leads, providing you with validated contact details, so you can focus on the right prospects."
            title1="Smart Filtering"
            title2="Validated Contact Info"
            subtitle1="Receive only high-intent leads."
            subtitle2="Get accurate phone numbers and emails."
          />
          <ContentSection
            isReversed
            icon={arrowUpIcon}
            title="Grow Your Home Service Business with Confidence"
            img={rocketImg}
            description="LeadQuiz helps home service businesses like yours grow by delivering high-quality, actionable leads from your Facebook and Google Ads. <b>With our platform, you can boost your marketing performance, keep your job calendar full, and scale your business faster.</b>"
          />
        </div>
        <ActionContentFooter
          title="Start Growing Your Home Service Business with LeadQuiz"
          subtitle="Ready to transform your lead generation process and get more from your Facebook and Google Ads? <b>Sign up today</b> and start attracting, engaging, and converting more leads for your home service business."
        />
        <Footer />
      </div>
    </div>
  );
}

export default HowItWorks;
