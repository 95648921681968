import { useEffect } from "react";
import Footer from "../homePage/footer/footer";
import Header from "../homePage/header/header";
import CardItem from "./cardItem/cardItem";
import styles from "./whoWeServe.module.scss";
import { Link } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";
import { Helmet } from "react-helmet";
import generalContracting from "src/assets/whoWeServe/generalContracting.png";
import HVAC from "src/assets/whoWeServe/HVAC.png";
import roofing from "src/assets/whoWeServe/roofing.png";
import solar from "src/assets/whoWeServe/solar.png";
import plumbing from "src/assets/whoWeServe/plumbing.png";
import electrical from "src/assets/whoWeServe/electrical.png";
import painting from "src/assets/whoWeServe/painting.png";
import pestControl from "src/assets/whoWeServe/pestControl.png";
import landscapingAndLawnCare from "src/assets/whoWeServe/landscaping&LawnCare.png";
import poolConstruction from "src/assets/whoWeServe/poolConstruction.png";
import cleaningServices from "src/assets/whoWeServe/cleaningServices.png";
import epoxyCoating from "src/assets/whoWeServe/epoxyCoating.png";
import flooring from "src/assets/whoWeServe/flooring.png";
import homeRenovation from "src/assets/whoWeServe/homeRenovation.png";
import garageDoorServices from "src/assets/whoWeServe/garageDoorServices.png";
import holidayLightInstallation from "src/assets/whoWeServe/holidayLightInstallation.png";
import windowCleaning from "src/assets/whoWeServe/windowCleaning.png";
import homeSecurity from "src/assets/whoWeServe/homeSecurity.png";
import applianceRepair from "src/assets/whoWeServe/applianceRepair.png";
import interiorDesign from "src/assets/whoWeServe/interiorDesign.png";
import furnitureAssembly from "src/assets/whoWeServe/furnitureAssembly.png";
import homeOrganization from "src/assets/whoWeServe/homeOrganization.png";
import masonry from "src/assets/whoWeServe/masonry.png";
import deckAndPatioConstruction from "src/assets/whoWeServe/deckAndPatioConstruction.png";
import fencing from "src/assets/whoWeServe/fencing.png";
import waterproofing from "src/assets/whoWeServe/waterproofing.png";

const WhoWeServe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={styles.outerWrapper}>
        <Helmet>
          <title>LeadQuiz | Who we serve</title>
          <meta
            name="description"
            content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
          />
          <link rel="canonical" href="https://leadquiz.com/who-we-serve" />
          <meta property="og:title" content="LeadQuiz | Who we serve" />
          <meta
            property="og:description"
            content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://leadquiz.com/who-we-serve" />
          <meta property="og:image" content="src/assets/homePage/devices.png" />
          <meta
            name="keywords"
            content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
          />
        </Helmet>
        <Header />
        <div className={styles.wrapper}>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.GENERAL_CONTRACTING}`}>
            <CardItem img={generalContracting} text="General Contracting" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.HVAC}`}>
            <CardItem
              img={HVAC}
              text="HVAC (Heating, Ventilation, and Air Conditioning)"
            />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.ROOFING}`}>
            <CardItem img={roofing} text="Roofing" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.SOLAR}`}>
            <CardItem img={solar} text="Solar" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.PLUMBING}`}>
            <CardItem img={plumbing} text="Plumbing" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.ELECTRICAL}`}>
            <CardItem img={electrical} text="Electrical" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.PAINTING}`}>
            <CardItem img={painting} text="Painting" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.PEST_CONTROL}`}>
            <CardItem img={pestControl} text="Pest Control" />
          </Link>
          <Link
            to={`${ROUTES.WHO_WE_SERVE}${ROUTES.LANDSCAPING_AND_LAWN_CARE}`}
          >
            <CardItem
              img={landscapingAndLawnCare}
              text="Landscaping & Lawn Care"
            />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.POOL_CONSTRUCTION}`}>
            <CardItem img={poolConstruction} text="Pool Construction" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.CLEANING_SERVICES}`}>
            <CardItem img={cleaningServices} text="Cleaning Services" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.EPOXY_COATING}`}>
            <CardItem img={epoxyCoating} text="Epoxy Coating" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.FLOORING}`}>
            <CardItem img={flooring} text="Flooring" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOME_RENOVATION}`}>
            <CardItem img={homeRenovation} text="Home Renovation" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.GARAGE_DOOR_SERVICES}`}>
            <CardItem img={garageDoorServices} text="Garage Door Services" />
          </Link>
          <Link
            to={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOLIDAY_LIGHT_INSTALLATION}`}
          >
            <CardItem
              img={holidayLightInstallation}
              text="Holiday Light Installation"
            />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.WINDOW_CLEANING}`}>
            <CardItem img={windowCleaning} text="Window Cleaning" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOME_SECURITY}`}>
            <CardItem img={homeSecurity} text="Home Security" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.APPLIANCE_REPAIR}`}>
            <CardItem img={applianceRepair} text="Appliance Repair" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.INTERIOR_DESIGN}`}>
            <CardItem img={interiorDesign} text="Interior Design" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.FURNITURE_ASSEMBLY}`}>
            <CardItem img={furnitureAssembly} text="Furniture Assembly" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOME_ORGANIZATION}`}>
            <CardItem img={homeOrganization} text="Home Organization" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.MASONRY}`}>
            <CardItem img={masonry} text="Masonry" />
          </Link>
          <Link
            to={`${ROUTES.WHO_WE_SERVE}${ROUTES.DECK_AND_PATIO_CONSTRUCTION}`}
          >
            <CardItem
              img={deckAndPatioConstruction}
              text="Deck and Patio Construction"
            />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.FENCING}`}>
            <CardItem img={fencing} text="Fencing" />
          </Link>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.WATERPROOFING}`}>
            <CardItem img={waterproofing} text="Waterproofing" />
          </Link>
        </div>
      </div>
      <div className={styles.footerOuterWrapper}>
        <div className={styles.footerWrapper}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default WhoWeServe;
