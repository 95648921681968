import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAvailablePlansListApi } from "src/_utils/api/api";
import { IPlanData } from "src/pages/interfaces/interfaces";
import PaymentPlan from "./paymentPlan/paymentPlan";
import styles from "./payments.module.scss";
import PaymentType from "./paymentType/paymentType";

const Payments = () => {
  const YEAR_PAYMENT_TYPE = "year";
  const MONTH_PAYMENT_TYPE = "month";

  const isUserPlanActive = useSelector((state: any) => state.isUserPlanActive);
  const currentUserPlan = useSelector((state: any) => state.currentUserPlan);

  const [selectedPaymentType, setselectedPaymentType] = useState<
    "year" | "month"
  >(MONTH_PAYMENT_TYPE);
  const [plansList, setPlansList] = useState<IPlanData[] | null>(null);
  const [currentPlan, setCurrentPlan] = useState<any>(null);
  const [isCurrentPlanAvailable, setIsCurrentPlanAvailable] = useState(false);
  const [showAllPlans, setShowAllPlans] = useState(false);

  useEffect(() => {
    getAvailablePlansListApi()
      .then((res) => {
        if (!currentUserPlan?.needUpdatePlan && isUserPlanActive) {
          const foundPlans = res.data.data.filter(
            (plan: IPlanData) => plan.id !== currentUserPlan?.planId
          );
          setPlansList(foundPlans);
        } else {
          setPlansList(res.data.data);
        }

        const foundPlan = res.data.data.find(
          (plan: IPlanData) => plan.id === currentUserPlan?.planId
        );
        setCurrentPlan(foundPlan);
      })
      .catch((err) => console.log(err));
  }, [currentUserPlan, isUserPlanActive]);

  useEffect(() => {
    if (currentPlan?.name && isUserPlanActive) {
      setIsCurrentPlanAvailable(true);
    } else {
      setIsCurrentPlanAvailable(false);
    }
  }, [currentUserPlan, currentPlan]);

  const enterpriseList = [
    "Custom Questions",
    "Customized Domain Solutions",
    "Customized Submission Limits",
    "Designated Account Manager",
  ];

  const visiblePlans = showAllPlans ? plansList : plansList?.slice(0, 3);

  return (
    <div className={styles.wrapper}>
      <PaymentType
        selectedPaymentType={selectedPaymentType}
        setselectedPaymentType={setselectedPaymentType}
      />
      <div className={styles.paymentPlansWrapper}>
        {visiblePlans?.map((el: IPlanData) => (
          <PaymentPlan
            price={
              selectedPaymentType === YEAR_PAYMENT_TYPE
                ? el.stripePrices.pricePerYear.unit_amount
                : selectedPaymentType === MONTH_PAYMENT_TYPE &&
                  el.stripePrices.pricePerMonth.unit_amount
            }
            pricePerForm={
              selectedPaymentType === YEAR_PAYMENT_TYPE
                ? el.formPrice?.displayYearlyPrice
                : selectedPaymentType === MONTH_PAYMENT_TYPE &&
                  el.formPrice?.displayMonthlyPrice
            }
            title={el.name}
            maxDomains={el.maxDomains}
            maxSubmissionsPerMonth={el.maxSubmissionsPerMonth}
            key={el.id}
            id={el.id}
            priceId={
              selectedPaymentType === YEAR_PAYMENT_TYPE
                ? el.stripePrices.pricePerYear.id
                : selectedPaymentType === MONTH_PAYMENT_TYPE &&
                  el.stripePrices.pricePerMonth.id
            }
            isNeedToContactSales={el.needToContactSales}
            isCurrentPlanAvailable={isCurrentPlanAvailable}
            isYearly={selectedPaymentType === YEAR_PAYMENT_TYPE}
            isPersonalPlan={el.userId !== undefined}
          />
        ))}
        {showAllPlans && (
          <PaymentPlan title="Enterprise" list={enterpriseList} />
        )}
      </div>
      {plansList && plansList.length > 2 && (
        <button
          className={styles.showMoreBtn}
          onClick={() => setShowAllPlans(!showAllPlans)}
        >
          {showAllPlans ? "Show less" : "Show more"}
        </button>
      )}
    </div>
  );
};

export default Payments;
