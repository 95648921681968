import Header from "src/pages/homePage/header/header";
import styles from "./../article.module.scss";
import Footer from "src/pages/homePage/footer/footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const HowToCreateAForm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Support - How to create a form</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link rel="canonical" href="https://leadquiz.com/support/how-to-create-a-form" />
        <meta property="og:title" content="LeadQuiz | Support - How to create a form" />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://leadquiz.com/support/how-to-create-a-form" />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <h1>How to Create a Form with LeadQuiz</h1>
        <p>
          LeadQuiz makes it easy to create customizable forms for your home
          service business, whether you want to generate leads from Facebook
          Ads, Google Ads, or your website. Follow the steps below to create a
          form and start capturing leads efficiently.
        </p>

        <b className={styles.step}>Step 1: Log in to Your LeadQuiz Account</b>
        <ul>
          <li>
            Go to{" "}
            <a href="https://leadquiz.com/" target="_blank">
              leadquiz.com
            </a>{" "}
            and log in with your credentials.
          </li>
          <li>
            If you don’t have an account yet, click “Sign Up” and follow the
            instructions to create a new account.
          </li>
        </ul>

        <b className={styles.step}>Step 2: Access the Form Builder</b>
        <ul>
          <li>Once logged in, navigate to the dashboard.</li>
          <li>Click on “Form Builder” from the main navigation menu.</li>
        </ul>

        <b className={styles.step}>
          Step 3: Choose a Prebuilt Template or Start from Scratch
        </b>
        <ul>
          <li>
            You’ll have the option to choose from several{" "}
            <b>prebuilt templates</b> that are optimized for different
            industries (e.g., Home Services, Plumbing, HVAC).
          </li>
          <li>
            If you'd like to create a form from scratch, click “Create New Form”
            and proceed to customize it as needed.
          </li>
        </ul>

        <b className={styles.step}>Step 4: Add Your Questions</b>
        <ul className={styles.numericUl}>
          <li>
            <b>Click on "Add New Field"</b> to add a new question to your form.
          </li>
          <li>
            <b>Select a question type:</b> You can choose from various types of
            questions, such as:
          </li>
          <ul className={styles.subUl}>
            <li>
              <b>Text Input</b> (e.g., "What is your name?")
            </li>
            <li>
              <b>Multiple Choice</b> (e.g., "What service are you interested
              in?")
            </li>
            <li>
              <b>Checkboxes</b> (e.g., "Select all that apply")
            </li>
            <li>
              <b>Dropdown Menus</b> for more structured options.
            </li>
          </ul>
          <li>
            Customize each question by typing in the question text and adjusting
            any other settings such as placeholders, default values, or required
            fields.
          </li>
        </ul>

        <b className={styles.step}>Step 5: Organize the Form Layout</b>
        <ul>
          <li>
            Drag and drop the fields to rearrange the order of your questions.
          </li>
          <li>
            Use the <b>section headers</b> to break the form into different
            sections if needed (e.g., "Contact Information" and "Project
            Details").
          </li>
        </ul>

        <b className={styles.step}>Step 6: Customize Form Settings</b>
        <ul>
          <li>
            Navigate to the <b>Settings</b> tab to configure important options
            such as:
          </li>
          <ul className={styles.subUl}>
            <li>
              <b>Submission notifications:</b> Decide if you want to be notified
              by email when a form is submitted.
            </li>
            <li>
              <b>CRM Integration:</b> If you use a CRM like HubSpot, Jobber, or
              GoHighLevel, set up the integration so that submissions flow
              directly into your CRM.
            </li>
            <li>
              <b>Thank You Page:</b> Set the page that users will be redirected
              to after they complete the form.
            </li>
          </ul>
        </ul>

        <b className={styles.step}>Step 7: Preview and Test Your Form</b>
        <ul>
          <li>
            Once you've added your questions, click “Preview” to see how your
            form will look.
          </li>
          <li>
            Test the form by filling it out and submitting it to ensure that
            everything works smoothly.
          </li>
        </ul>

        <b className={styles.step}>
          Step 8: Embed or Share the Form and Configure Tracking
        </b>
        <p>
          Once you're satisfied with your form, it’s time to publish and share
          it. Here's how to set up the form, including adding a custom subdomain
          and installing tracking pixels for lead tracking.
        </p>
        <ul className={styles.numericUl}>
          <li>Publish the Form</li>
          <ul className={styles.subUl}>
            <li>After clicking "Publish," you’ll see options to either:</li>
            <ul className={styles.subUl}>
              <li>
                Embed the form on your website using the provided HTML code.
              </li>
              <li>
                Share the form link directly on social media, ads, or email
                campaigns.
              </li>
            </ul>
          </ul>
          <li>Set Up a Custom Subdomain</li>
          <ul className={styles.subUl}>
            <li>
              If you’d like to use a custom subdomain (e.g.,{" "}
              <span>yourbusiness.leadquiz.com</span>), simply enter your desired
              subdomain in the provided field in the LeadQuiz settings.
            </li>
            <ul className={styles.subUl}>
              <li>
                You do not need to configure any DNS records manually—our
                software automatically handles the setup for you.
              </li>
              <li>
                Choose the wording for your subdomain, and it will be formatted
                as <span>yourbusiness.leadquiz.com.</span>
              </li>
            </ul>
          </ul>
          <li>Install Facebook and/or Google Tracking Pixels</li>
          <ul className={styles.subUl}>
            <li>
              To ensure that your advertising platforms (such as Facebook or
              Google) can track the leads generated through your form, you’ll
              need to install tracking pixels.
            </li>
            <ul className={styles.subUl}>
              <li>
                In the Settings tab, navigate to the section for Tracking
                Pixels.
              </li>
              <li>
                Choose whether to add a Facebook Pixel, Google Pixel, or both,
                depending on which platform you are using to advertise.
              </li>
              <ul className={styles.subUl}>
                <li>For Facebook: Enter your Facebook Pixel ID.</li>
                <li>
                  For Google: Enter your Google Ads Conversion Tracking Tag.
                </li>
              </ul>
              <li>
                Once the pixel is installed, both platforms will be able to
                track conversions and optimize your campaigns based on lead
                data.
              </li>
            </ul>
          </ul>
        </ul>
        <p>
          Make sure your pixels are installed before launching any ad campaigns
          to ensure proper tracking of your leads.
        </p>

        <b className={styles.step}>Step 9: Review and Manage Submissions</b>
        <ul>
          <li>
            Head back to the LeadQuiz dashboard to view all your form
            submissions in one place.
          </li>
          <li>
            You can export submissions to Excel or automatically sync them to
            your CRM for easy management.
          </li>
        </ul>
        <b className={styles.step}>Tips for Success:</b>
        <ul>
          <li>
            <b>Keep it short:</b> The fewer fields you have, the more likely
            users are to complete the form.
          </li>
          <li>
            <b>Be specific:</b> Use clear and concise questions to ensure you
            get the information you need from potential leads.
          </li>
          <li>
            <b>Test before launching:</b> Always preview and test the form to
            avoid any technical issues.
          </li>
        </ul>
      </div>
      <div className={styles.footerWrap}>
        <Footer />
      </div>
    </div>
  );
};

export default HowToCreateAForm;
