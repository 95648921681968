import styles from "./paidCallList.module.scss";
import PaidCallUserItem from "./paidCallUserItem/paidCallUserItem";

const PaidCallList: React.FC<any> = ({
  paidCallUsersList,
  setIsLoading,
}: any) => {
  return (
    <div className={styles.wrapper}>
      {paidCallUsersList &&
        paidCallUsersList.map((item: any, index: number) => (
          <PaidCallUserItem
            key={index}
            data={item}
            setIsLoading={setIsLoading}
          />
        ))}
    </div>
  );
};

export default PaidCallList;
