import Header from "src/pages/homePage/header/header";
import styles from "./../article.module.scss";
import Footer from "src/pages/homePage/footer/footer";
import { useEffect } from "react";
import step1 from "src/assets/support/googleAdsSetup/step1.jpg";
import step2 from "src/assets/support/googleAdsSetup/step2.jpg";
import step3 from "src/assets/support/googleAdsSetup/step3.jpg";
import step4 from "src/assets/support/googleAdsSetup/step4.jpg";
import step5 from "src/assets/support/googleAdsSetup/step5.jpg";
import { Helmet } from "react-helmet";

const HowToTrackGoogleAds = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Support - How to track google ads</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link
          rel="canonical"
          href="https://leadquiz.com/support/how-to-track-google-ads"
        />
        <meta
          property="og:title"
          content="LeadQuiz | Support - How to track google ads"
        />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://leadquiz.com/support/how-to-track-google-ads"
        />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <h1>How to Track Google Ads Conversions with LeadQuiz</h1>
        <p>
          Tracking conversions from your Google Ads is crucial to understanding
          how well your campaigns are performing. With LeadQuiz, you can easily
          set up conversion tracking to monitor form submissions from your
          Google Ads. Here’s how to integrate Google Ads conversion tracking
          with your LeadQuiz forms.
        </p>

        <b className={styles.step}>
          Step 1: Create a Conversion Action in Google Ads
        </b>
        <ul className={styles.numericUl}>
          <li>
            <b>Log in to your Google Ads account.</b>
          </li>
          <li>
            In the left-hand menu, click on <b>Tools & Settings</b> and navigate
            to <b>Conversions</b> under the “Measurement” section.
          </li>
          <li>
            Click on the <b>+ New Conversion Action</b> button.
          </li>
          <li>
            Choose <b>Website</b> as the conversion type.
          </li>
          <ul className={styles.subUl}>
            <li>
              <b>Category</b>: Select <b>Lead</b> as the category, as you’re
              tracking leads from your form submissions.
            </li>
            <li>
              <b>Conversion name</b>: Name your conversion action something
              descriptive, such as "LeadQuiz Form Submission."
            </li>
            <li>
              <b>Value</b>: You can assign a value for each conversion, or you
              can leave it as "Don't use a value for this conversion."
            </li>
            <li>
              <b>Count</b>: Choose whether you want to count every conversion or
              only one conversion per click. For lead generation, it’s typically
              better to select <b>One</b> conversion per click.
            </li>
            <li>
              <b>Conversion window</b>: This is the time period within which a
              conversion is recorded after someone clicks on your ad. The
              default is 30 days.
            </li>
          </ul>
          <li>
            <b>Click Create and Continue</b>. Google will generate a conversion
            tracking tag for you.
          </li>
        </ul>
        <br />
        <img src={step1} alt="step 1" />
        <img src={step2} alt="step 2" />
        <img src={step3} alt="step 3" />
        <img src={step4} alt="step 4" />

        <b className={styles.step}>
          Step 2: Install the Conversion Tag in LeadQuiz
        </b>
        <ul className={styles.numericUl}>
          <li>
            <b>Copy the Global Site Tag (gtag.js):</b>
          </li>
          <ul className={styles.subUl}>
            <li>
              After you create your conversion action, Google Ads will provide
              you with a <b>Global Site Tag</b> (gtag.js) and an{" "}
              <b>Event Snippet</b>.
            </li>
            <br />
            <img src={step5} alt="step 5" />
          </ul>
          <li>
            <b>Log in to LeadQuiz</b> and open the form you want to track.
          </li>
          <li>
            In the form editor, navigate to the <b>Settings</b> tab.
          </li>
          <li>
            Scroll down to the <b>Tracking Scripts</b> section.
          </li>
          <li>
            <b>Paste the Global Site Tag</b> into the <b>Head or Body</b>{" "}
            section of your LeadQuiz form.
          </li>
          <ul className={styles.subUl}>
            <li>
              This ensures that Google Ads will track all visits to your form
              pages.
            </li>
          </ul>
          <li>
            <b>Save your form</b> after adding the tracking script.
          </li>
        </ul>

        <b className={styles.step}>
          Step 3: Set Up the Conversion Event in LeadQuiz
        </b>
        <ul className={styles.numericUl}>
          <li>
            Now that the Global Site Tag is installed, you need to install the{" "}
            <b>Event Snippet</b> to track conversions when a lead successfully
            submits the form.
          </li>
          <li>
            Copy the <b>Event Snippet</b> provided by Google Ads.
          </li>
          <li>
            In LeadQuiz, go to your <b>Thank-You Page</b> (the page users are
            redirected to after submitting a form).
          </li>
          <li>
            In the <b>Tracking Scripts</b> section of the Thank-You page editor,{" "}
            <b>paste the Event Snippet</b> code.
          </li>
          <ul className={styles.subUl}>
            <li>
              This snippet fires when someone reaches your Thank-You page,
              meaning a successful form submission has occurred.
            </li>
          </ul>
          <li>
            <b>Save your changes</b> to ensure the Event Snippet is installed on
            the Thank-You page.
          </li>
        </ul>

        <b className={styles.step}>Step 4: Verify Conversion Tracking</b>
        <ul className={styles.numericUl}>
          <li>
            Once the tracking code is added, you can verify if the tracking
            works by clicking on your ad and completing a test form submission.
          </li>
          <li>
            In your Google Ads account, navigate to <b>Tools & Settings</b>{" "}
            {">"} <b>Conversions</b>, and you should see your conversion actions
            recording submissions.
          </li>
          <li>
            You can also use Google’s <b>Tag Assistant</b> tool to ensure that
            the conversion tracking tags are firing correctly when someone
            submits a form.
          </li>
        </ul>

        <b className={styles.step}>Step 5: Monitor Conversions in Google Ads</b>
        <p>
          After the setup is complete, Google Ads will start tracking
          conversions for every successful form submission. You can view this
          data in your <b>Google Ads dashboard</b>:
        </p>
        <ul>
          <li>
            Go to <b>Campaigns</b> in Google Ads.
          </li>
          <li>
            Click on the <b>Conversions</b> column to monitor how many leads
            your ads are generating.
          </li>
        </ul>

        <b className={styles.step}>Conclusion</b>
        <p>
          By setting up Google Ads conversion tracking with LeadQuiz, you can
          measure the effectiveness of your campaigns and optimize them for
          better performance. This integration helps you gain valuable insights
          into how well your ads are converting traffic into leads.
        </p>
        <p>
          If you have any questions or need further assistance, feel free to
          contact our support team at support@perstrive.com.
        </p>
      </div>
      <div className={styles.footerWrap}>
        <Footer />
      </div>
    </div>
  );
};

export default HowToTrackGoogleAds;
