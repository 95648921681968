import { useEffect, useState } from "react";
import styles from "./trackingAndTagging.module.scss";
import {
  getFacebookPixelApi,
  getFormsApi,
  getGoogleAdsIdApi,
  getPublishedFormApi,
  removeFormGoogleAdsApi,
  removeFormPbPixelApi,
  setFormFbPixelApi,
  setFormGoogleAdsApi,
} from "src/_utils/api/api";
import FbPixelItem from "./fbPixelItem/fbPixelItem";
import faceBookPixelImg from "src/assets/settingsPage/facebookPixel.png";
import googleAdsImg from "src/assets/settingsPage/googleAds.jpg";
import FbModal from "./fbModal/fbModal";
import GoogleAdsItem from "./googleAdsItem/googleAdsItem";
import GoogleAdsModal from "./googleAdsModal/googleAdsModal";
import { RotatingLines } from "react-loader-spinner";
import ActionModal from "src/_elements/actionModal/actionModal";

const TrackingAndTagging = () => {
  const [fbPixelArray, setFbPixelArray] = useState([]);
  const [googleAdsArray, setGoogleAdsArray] = useState([]);
  const [isShowFbModal, setIsShowFbModal] = useState(false);
  const [isShowGoogleAdsModal, setIsShowGoogleAdsModal] = useState(false);
  const [publishedFormsArray, setPublishedFormsArray] = useState<any>([]);
  const [selectedFbPixelForm, setSelectedFbPixelForm] = useState<any>(null);
  const [selectedGoogleAdsForm, setSelectedGoogleAdsForm] = useState<any>(null);
  const [selectedFbPixel, setSelectedFbPixel] = useState("");
  const [selectedGoogleAds, setSelectedGoogleAds] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isGoogleAdsLoading, setIsGoogleAdsLoading] = useState(false);
  const [isGoogleAdsSaved, setIsGoogleAdsSaved] = useState(false);

  const [isFbPixelLoading, setIsFbPixelLoading] = useState(false);
  const [isFbPixelSaved, setIsFbPixelSaved] = useState(false);

  const [
    isShowDeleteFbPixelFromFormModal,
    setIsShowDeleteFbPixelFromFormModal,
  ] = useState(false);
  const [
    isShowDeleteGoogleAdsFromFormModal,
    setIsShowDeleteGoogleAdsFromFormModal,
  ] = useState(false);

  useEffect(() => {
    if (isFbPixelSaved) {
      setTimeout(() => {
        setIsFbPixelSaved(false);
      }, 3000);
    }
  }, [isFbPixelSaved]);

  useEffect(() => {
    if (isGoogleAdsSaved) {
      setTimeout(() => {
        setIsGoogleAdsSaved(false);
      }, 3000);
    }
  }, [isGoogleAdsSaved]);

  const handleCloseDeleteFbPixelFromFormModal = () => {
    setIsShowDeleteFbPixelFromFormModal(false);
  };

  const handleCloseDeleteGoogleAdsFromFormModal = () => {
    setIsShowDeleteGoogleAdsFromFormModal(false);
  };

  const handleCloseFbModal = () => {
    setIsShowFbModal(false);
  };

  const handleCloseGoogleAdsModal = () => {
    setIsShowGoogleAdsModal(false);
  };

  useEffect(() => {
    getFacebookPixelApi().then((res) => {
      setFbPixelArray(res.data.data);
    });

    getGoogleAdsIdApi().then((res) => {
      setGoogleAdsArray(res.data.data);
    });

    getFormsApi().then((res) => {
      const publishedForms = res.data.data.filter(
        (res: any) => res.form.isPublished
      );

      setPublishedFormsArray(publishedForms);
      setSelectedFbPixelForm(publishedForms[0]);
      setSelectedGoogleAdsForm(publishedForms[0]);
      setIsLoading(false);
    });
  }, [isLoading]);

  useEffect(() => {
    selectedFbPixelForm &&
      getPublishedFormApi(selectedFbPixelForm?.cname?.subDomain).then((res) => {
        setSelectedFbPixel(res.data.data?.facebookPixelData?.id ?? "");
      });
  }, [selectedFbPixelForm]);

  useEffect(() => {
    selectedGoogleAdsForm &&
      getPublishedFormApi(selectedGoogleAdsForm?.cname?.subDomain).then(
        (res) => {
          setSelectedGoogleAds(res.data.data?.googleAdsData?.id ?? "");
        }
      );
  }, [selectedGoogleAdsForm]);

  const handleFbPixelChange = (e: any) => {
    setSelectedFbPixel(e.target.value);
  };

  const handleGoogleAdsChange = (e: any) => {
    setSelectedGoogleAds(e.target.value);
  };

  const handleSaveFbPixelToForm = () => {
    setIsFbPixelLoading(true);

    const payload = {
      id: selectedFbPixel,
    };

    setFormFbPixelApi(payload, selectedFbPixelForm?.form?.id).then(() => {
      setIsFbPixelLoading(false);
      setIsFbPixelSaved(true);
    });
  };

  const handleSaveGoogleAdsToForm = () => {
    setIsGoogleAdsLoading(true);

    const payload = {
      id: selectedGoogleAds,
    };

    setFormGoogleAdsApi(payload, selectedGoogleAdsForm?.form?.id).then(() => {
      setIsGoogleAdsLoading(false);
      setIsGoogleAdsSaved(true);
    });
  };

  const handleRemoveFbPixelFromForm = () => {
    selectedFbPixelForm && removeFormPbPixelApi(selectedFbPixelForm?.form?.id);
    setIsShowDeleteFbPixelFromFormModal(false);
    setSelectedFbPixel("");
  };

  const handleRemoveGoogleAdsFromForm = () => {
    selectedGoogleAdsForm &&
      removeFormGoogleAdsApi(selectedGoogleAdsForm?.form?.id);
    setIsShowDeleteGoogleAdsFromFormModal(false);
    setSelectedGoogleAds("");
  };

  return (
    <>
      <div className={styles.wrapper}>
        <p className={styles.title}>Tracking & Tagging</p>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.imgWrap}>
              <img width="180px" src={faceBookPixelImg} alt="facebook pixel" />
            </div>
            <div>
              <p className={styles.label}>Published form:</p>
              <select
                value={selectedFbPixelForm ? selectedFbPixelForm.form.id : ""}
                onChange={(e) =>
                  setSelectedFbPixelForm(
                    publishedFormsArray[e.target.selectedIndex]
                  )
                }
                className={styles.formSelect}
              >
                {publishedFormsArray.map((option: any, index: number) => (
                  <option key={index} value={option.form.id}>
                    {option.form.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <p className={styles.label}>Facebook pixel:</p>
              <select
                value={selectedFbPixel ?? ""}
                onChange={handleFbPixelChange}
                className={styles.formSelect}
              >
                <option value="">Not selected</option>
                {fbPixelArray.map((option: any, index: number) => (
                  <option key={index} value={option.id}>
                    {option.displayName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              {selectedFbPixel && (
                <button
                  className={styles.saveBtn}
                  onClick={handleSaveFbPixelToForm}
                >
                  {!isFbPixelLoading ? (
                    "Save"
                  ) : (
                    <RotatingLines
                      visible={true}
                      width="30"
                      strokeWidth="5"
                      animationDuration="0.75"
                      strokeColor="white"
                    />
                  )}
                </button>
              )}
              {isFbPixelSaved && <p className={styles.savedMessage}>Saved!</p>}
            </div>
            {selectedFbPixel && (
              <button
                onClick={() => setIsShowDeleteFbPixelFromFormModal(true)}
                className={styles.removeBtn}
              >
                Remove
              </button>
            )}
          </div>
          <div className={styles.itemsWrap}>
            <button
              onClick={() => setIsShowFbModal(true)}
              className={styles.addBtn}
            >
              Add new facebook pixel
            </button>
            {fbPixelArray.map((item, index) => (
              <FbPixelItem
                key={index}
                item={item}
                setIsLoading={setIsLoading}
              />
            ))}
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.imgWrap}>
              <img width="100px" src={googleAdsImg} alt="google ads" />
            </div>
            <div>
              <p className={styles.label}>Published form:</p>
              <select
                value={
                  selectedGoogleAdsForm ? selectedGoogleAdsForm.form.id : ""
                }
                onChange={(e) =>
                  setSelectedGoogleAdsForm(
                    publishedFormsArray[e.target.selectedIndex]
                  )
                }
                className={styles.formSelect}
              >
                {publishedFormsArray.map((option: any, index: number) => (
                  <option key={index} value={option.form.id}>
                    {option.form.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <p className={styles.label}>Google ads:</p>
              <select
                value={selectedGoogleAds ?? ""}
                onChange={handleGoogleAdsChange}
                className={styles.formSelect}
              >
                <option value="">Not selected</option>
                {googleAdsArray.map((option: any, index: number) => (
                  <option key={index} value={option.id}>
                    {option.displayName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              {selectedGoogleAds && (
                <button
                  className={styles.saveBtn}
                  onClick={handleSaveGoogleAdsToForm}
                >
                  {!isGoogleAdsLoading ? (
                    "Save"
                  ) : (
                    <RotatingLines
                      visible={true}
                      width="30"
                      strokeWidth="5"
                      animationDuration="0.75"
                      strokeColor="white"
                    />
                  )}
                </button>
              )}
              {isGoogleAdsSaved && (
                <p className={styles.savedMessage}>Saved!</p>
              )}
            </div>
            {selectedGoogleAds && (
              <button
                onClick={() => setIsShowDeleteGoogleAdsFromFormModal(true)}
                className={styles.removeBtn}
              >
                Remove
              </button>
            )}
          </div>
          <div className={styles.itemsWrap}>
            <button
              onClick={() => setIsShowGoogleAdsModal(true)}
              className={styles.addBtn}
            >
              Add new google ads
            </button>
            {googleAdsArray.map((item, index) => (
              <GoogleAdsItem
                key={index}
                item={item}
                setIsLoading={setIsLoading}
              />
            ))}
          </div>
        </div>
      </div>
      <FbModal
        isShow={isShowFbModal}
        handleClose={handleCloseFbModal}
        setIsLoading={setIsLoading}
      />
      <GoogleAdsModal
        isShow={isShowGoogleAdsModal}
        handleClose={handleCloseGoogleAdsModal}
        setIsLoading={setIsLoading}
      />
      <ActionModal
        title={`Remove facebook pixel tracker from "${selectedFbPixelForm?.form?.name}" form?`}
        subtitle="This will remove the facebook pixel tracker from selected form."
        isShowModal={isShowDeleteFbPixelFromFormModal}
        handleCloseModal={handleCloseDeleteFbPixelFromFormModal}
        handleAction={handleRemoveFbPixelFromForm}
        actionButtonText="Remove"
      />
      <ActionModal
        title={`Remove google ads tracker from "${selectedGoogleAdsForm?.form?.name}" form?`}
        subtitle="This will remove the google ads tracker from selected form."
        isShowModal={isShowDeleteGoogleAdsFromFormModal}
        handleCloseModal={handleCloseDeleteGoogleAdsFromFormModal}
        handleAction={handleRemoveGoogleAdsFromForm}
        actionButtonText="Remove"
      />
    </>
  );
};

export default TrackingAndTagging;
